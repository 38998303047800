import React, { Component } from "react";
import TextField from "./TextField";
import SelectOption from "./SelectOption";
import PayPalCheckout from "./PayPalCheckout";
import Cards from "react-credit-cards";

const Payment = props => {
  const cardForm = () => {
    return props.selectedPaymentOption === 'card' && (
      <div class="row">
        <div class="col-md-6">

          <Cards
            cvc={props.payment.code}
            expiry={props.payment.expiry}
            focus={"name"}
            name={props.payment.name}
            number={props.payment.num}
          />
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <TextField
              formHandler={props.formHandler}
              name="name"
              type="text"
              inValid={null}
              errorMsg={""}
              value={props.payment.name}
              placeholder="John Doe"
              label="Name of Card Owner"
            />
            <TextField
              formHandler={props.formHandler}
              name="num"
              type="number"
              inValid={null}
              errorMsg={""}
              value={props.payment.num}
              placeholder="411111111111111"
              label="Card Number"
            />
            <TextField
              formHandler={props.formHandler}
              name="expiry"
              type="number"
              inValid={false}
              errorMsg={""}
              value={props.payment.expiry}
              placeholder="11/21"
              label="Expiration"
            />
            <TextField
              formHandler={props.formHandler}
              name="code"
              type="number"
              inValid={false}
              errorMsg={""}
              value={props.payment.code}
              placeholder="000"
              label="Security Code"
            />
          </div>

        </div>
      </div>)
  }

  const paypalForm = () => {
    return props.paymentMethods.filter(x => x.method_type === 'paypal').length > 0 && props.selectedPaymentOption === 'paypal' &&
      <PayPalCheckout id={props.paymentMethods.filter(x => x.method_type === 'paypal')[0].id} amount={props.amount} />
  }

  const existingCards = () => {
    return (
      <div>
        {props.savedPayments.length == 0 ? (
          <li className="list-group-item"> No Saved Card </li>
        ) : (
          <SelectOption
            selectedOption={props.payment.id}
            inValid={false}
            callback={props.selectedExistingCard}
            errorMsg={""}
            listOptions={props.savedPayments.map(x => {
              return {
                id: x.id,
                name: `${x.name} XXXX-XXXX-XXXX-${x.last_digits} ${x.month +
                  "/" +
                  x.year}`
              };
            })}
            name="selectedCard"
            label="Existing Cards"
          />
        )}
      </div>
    );
  };

  const paymentOptionsForm = () => {
    return <React.Fragment>
      <div className="payment-options-form">
        <label>
          <input type="radio" onChange={props.selectPaymentOption} value="card" checked={props.selectedPaymentOption === 'card'} /> Card
        </label>
        {cardForm()}
        <label>
          <input type="radio" onChange={props.selectPaymentOption} value="paypal" checked={props.selectedPaymentOption === 'paypal'} /> PayPal
        </label>
        {paypalForm()}
      </div>
    </React.Fragment>
  }

  return (
    <div>
      <h1 className="heading-cart-section">Payment</h1>
      {/*      <input
        type="radio"
        onChange={() => props.changePaymentOption(true)}
        value={true}
        checked={props.payment.isExisting}
        type="radio"
        label="payment"
      />{" "}
      <p className="payment-option-selction">show existing cards</p> */}
      {props.payment.isExisting ? (existingCards()) : (props.paymentMethods.length > 0 && paymentOptionsForm())}
    </div>
  );
}

export default Payment;
