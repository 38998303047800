import React from "react"
import PropTypes from "prop-types"
import FormContainer from "../container/FormContainer";
import SavedPrescription from "../components/SavedPrescription";
import { MultiPurposeList, lensFeatureList,stringInArray, showLensTypeLabel } from "../extensions/extensions";
import FeatureLens from "../container/FeatureLens";
import LensColor from "./LensColor";
//import {ListGroup} from 'react-bootstrap';
class LensSelection extends React.Component {
  render () {
    const {user,optionValues,isFrame,optionValueKey,formData,showDualElement,showColors,step,stepName,userPrescriptions,displayOtherBifocal,selectedColor,otherBifocalfunc,selected,changeTexture,isTabImage,lensColor,isPresc,isdctr,isModalActive,selectedOptionLens,nestedSelected,nestedSelection,intensitySelected,summary,solidIntensitySelected,showPrescriptionAdditionalOptions, togglePrescriptionAdditionalOptions}=this.props;

    const classForImg =(name) =>{
      if (name.includes('Distance') || name.includes('Reading') || name.trim() === "Digital Hi-Def Progressive") {
        return "img-prescription-type"
      } else if (stepName === 'Frame Type') {
        return "img-prescription-type-expanded"
      } else {
        return "img-prescription-lenses-type";
      }
     
    }

    const renderFeatureLens=(x,key)=>{
      return (<FeatureLens lensItem={x} showDualElement={showDualElement} formData={formData} nestedSelection={nestedSelection} nestedSelected={nestedSelected} displayOtherBifocal={displayOtherBifocal} otherBifocalfunc={otherBifocalfunc}  otherTypekey={key} optionValues={optionValues} step={step} selectedOptionLens={selectedOptionLens} callback={this.props.callback} optionValueKey={optionValueKey} />)
    }

    const renderFormContainer=(x,key)=>{
      return <React.Fragment>
        { (!x.name.toLowerCase().includes("later")) && this.props.others &&
        <div  className={"panel-collapse collapse"+(key==parseInt(optionValueKey)?' in': '')} aria-expanded={key==parseInt(optionValueKey)?'true':'false'} >
          <FormContainer prescriptionError={this.props.prescriptionError} updateTwoPDNumber={this.props.updateTwoPDNumber} dateHandler={this.props.dateHandler} selected={parseInt(this.props.selected)} formData={this.props.formData} userPrescriptions={this.props.userPrescriptions} selectPrescription={(id, type) => this.props.selectPrescription(id, type)} prescriptionId={this.props.prescriptionId} selectedPrescriptionType={this.props.selectedPrescriptionType} formHandler={this.props.formHandler} user={this.props.user} />
        </div>}
      </React.Fragment>
    }

    const renderCoatingFragment=(x,key)=>{
      return (<div className={"col-12 col-sm-6 col-md-12 lenses-types heading-feature-section-toggle "+( selectedOptionLens(x.id) ?'active':'')}  key={key} onClick={()=>this.props.callback(x.id,key,false, showLensTypeLabel(stepName) )}>

        <div className="panel-heading without-toggle choose-your-lenses-ov-pointer" data-toggle="collapse" data-parent={"#"+step} aria-expanded={key==parseInt(optionValueKey)?'true':'false'}  >

          { x.image != '' && <img src={x.image} className={classForImg(x.name)} alt={x.name} />}
          <div key={key+'s'} className={ x.image == '' ? "lenses-type-heading-without-product":"lenses-type-heading"}>
            {x.presentation}
          </div>
          <div key={key} className={ x.image == '' ? "lenses-type-subheading-without-product":"lenses-type-subheading"} dangerouslySetInnerHTML={{__html: x.option_description}} />
          {showColors && key==parseInt(optionValueKey) && <LensColor summary={summary} intensitySelected={intensitySelected} solidIntensitySelected={solidIntensitySelected} selectedColor={selectedColor}  formData={this.props.formData} changeTexture={changeTexture}  lensColor={lensColor}/>}
        </div>
        {renderFormContainer(x, key)}
      </div>)
    }

    const renderPrescriptionFragment=(x,key)=>{
      return <React.Fragment>
        <div className={"col-12 col-sm-6 col-md-12 lenses-types heading-feature-section-toggle"+(key==parseInt(optionValueKey) || selectedOptionLens(x.id) ?' active':'')}  key={key} onClick={()=>this.props.callback(x.id,key,false, showLensTypeLabel(stepName) )}>
          <div className="panel-heading without-toggle choose-your-lenses-ov-pointer" data-toggle="collapse" data-parent={"#"+step} aria-expanded={key==parseInt(optionValueKey)?'true':'false'}  >

            { x.image != '' && <img src={x.image} className={classForImg(x.name)} alt={x.name} />}
            <div key={key+'s'} className={ x.image == '' ? "lenses-type-heading-without-product":"lenses-type-heading"}>
              {x.presentation}
              {x.name.includes('Upload Photo') && <span className="recommended-tag">Recommended</span>}
            </div>
            <div key={key} className={ x.image == '' ? "lenses-type-subheading-without-product":"lenses-type-subheading"} dangerouslySetInnerHTML={{__html: x.option_description}} />
            {showColors && key==parseInt(optionValueKey) && <LensColor summary={summary} intensitySelected={intensitySelected} solidIntensitySelected={solidIntensitySelected} selectedColor={selectedColor}  formData={this.props.formData} changeTexture={changeTexture}  lensColor={lensColor}/>}
          </div>

          {renderFormContainer(x,key)}
        </div>
      </React.Fragment>
    }

    const renderCommonFragment=(x,key)=>{
      return <React.Fragment>
        <div className={"col-12 col-sm-6 col-md-12 lenses-types heading-feature-section-toggle "+(key==parseInt(optionValueKey) || selectedOptionLens(x.id) ?'active':'')}  key={key} onClick={()=>this.props.callback(x.id,key,false, showLensTypeLabel(stepName) )}>
          <div className="panel-heading without-toggle choose-your-lenses-ov-pointer" data-toggle="collapse" data-parent={"#"+step} aria-expanded={key==parseInt(optionValueKey)?'true':'false'}  >

            { x.image != '' && <img src={x.image} className={classForImg(x.name)} alt={x.name} />}
            <div key={key+'s'} className={ x.image == '' ? "lenses-type-heading-without-product":"lenses-type-heading"}>
              {x.presentation}
            </div>
            <div key={key} className={ x.image == '' ? "lenses-type-subheading-without-product":"lenses-type-subheading"} dangerouslySetInnerHTML={{__html: x.option_description}} />
            {showColors && key==parseInt(optionValueKey) && <LensColor summary={summary} intensitySelected={intensitySelected} solidIntensitySelected={solidIntensitySelected} selectedColor={selectedColor}  formData={this.props.formData} changeTexture={changeTexture}  lensColor={lensColor}/>}
          </div>

          {renderFormContainer(x,key)}
        </div>
      </React.Fragment>
    }


    const valueConditionBased=(x,key)=>{
      if(stringInArray(MultiPurposeList,x.name)) {
        return renderFeatureLens(x, key);
      } else if(stepName.toLowerCase().includes('coating')) {
        return renderCoatingFragment(x, key);
      } else if(stepName.toLowerCase() === 'prescription') {
        return renderPrescriptionFragment(x, key);
      } else {
        return renderCommonFragment(x, key);
      }
    }

    const chooseOptions=()=>{
    if(step>1||isFrame){
      return (<React.Fragment>
         
      {optionValues.map((x,key)=>(
        <React.Fragment key={key}>
          {valueConditionBased(x,key)}
        </React.Fragment>
        
      ))
      }
      {
        step === 5 && <i><p className="frame-type-notice">Extended range and prescriptions with prism may incur an extra fee. <a href="/contact-us" target="_blank" style={{color: "blue"}}>Contact us</a> before ordering if you would like to check.</p></i>
      }          
      </React.Fragment>)
    }
    else
    return;
    }
    return (<React.Fragment>{chooseOptions()}</React.Fragment> );
  }
}

LensSelection.propTypes = {
  optionValues: PropTypes.array,
  products: PropTypes.array,
  
};
export default LensSelection
