import React from "react";
import PropTypes from "prop-types";
import {
  spherePositiveRange,
  sphereNegativeRange,
  cylinderPositiveRange,
  cylinderNegativeRange,
  axisRange,
  cylinderRange,
  addRange,
  pupillaryRange,
  bothPupillary,
  base_direction_hori,
  base_direction_ver,
  prism_values
} from "../extensions/extensions";
import LensDropDown from "../container/LensDropdown";
import Select2 from "react-select2-wrapper";

class OnlinePresFields extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.formData.prescriptionName !==
      nextProps.formData.prescriptionName
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const props = this.props;
    const bothPDDropDown = () => {
      return (
        <div>
          <div className="Rect1">
            <LensDropDown
              indexVal={14}
              callback={props.formHandler}
              listOptions={bothPupillary}
              selectedOption={props.formData.osRight}
              checked={props.formData.twoPDNumber ? null : "disabled"}
              name="osRight"
              className="sel2"
              placeholder="Right / OD"
            />
          </div>
          <div className="Rect1">
            <LensDropDown
              indexVal={15}
              callback={props.formHandler}
              listOptions={bothPupillary}
              selectedOption={props.formData.osLeft}
              checked={props.formData.twoPDNumber ? null : "disabled"}
              name="osLeft"
              className="sel2"
              placeholder="Left / OS"
            />
          </div>{" "}
        </div>
      );
    };

    return (
      <React.Fragment>
        <table className="table space">
          <thead>
            <tr>
              <th scope="col" className="extra-prism-th-row-label-centered"></th>
              <th scope="col" className="extra-prism-th-row-label-centered">
                Sphere (SPH)
              </th>
              <th scope="col" className="extra-prism-th-row-label-centered">
                Cylinder (CYL)
              </th>
              <th scope="col" className="extra-prism-th-row-label-centered">
                Axis
              </th>
              <th scope="col" className="extra-prism-th-row-label-centered">
                Add
              </th>
              <th scope="col" className="extra-prism-th-row-label-centered"></th>
              <th scope="col" className="extra-prism-th-row-label-centered"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="extra-prism-th-column-label-centered">OD (Right)</th>
              <td className="extra-prism-td-label-centered extra-prism-select2-td-label">
                <Select2
                  data={[
                    {
                      text: "0.00 / Plano / PL",
                      id: "0.00 / Plano / PL"
                    },
                    {
                      text: "-",
                      children: sphereNegativeRange.map((optval, i) => ({
                        text: optval,
                        id: optval
                      }))
                    },
                    {
                      text: "+",
                      children: spherePositiveRange.map((optval, i) => ({
                        text: `+ ${optval}`,
                        id: `+ ${optval}`
                      }))
                    },
                    {
                      text: "Balance / Bal",
                      id: "Balance / Bal"
                    }
                  ]}
                  options={{
                    placeholder: "select"
                  }}
                  value={props.formData.rightOptionSphere}
                  onChange={props.formHandler}
                  name="rightOptionSphere"
                />
              </td>
              <td className="extra-prism-td-label-centered extra-prism-select2-td-label">
                <Select2
                  data={[
                    {
                      text: "Blank / SPH / 0.00",
                      id: "Blank / SPH / 0.00"
                    },
                    {
                      text: "-",
                      children: cylinderNegativeRange.map((optval, i) => ({
                        text: optval,
                        id: optval
                      }))
                    },
                    {
                      text: "+",
                      children: cylinderPositiveRange.map((optval, i) => ({
                        text: `+ ${optval}`,
                        id: `+ ${optval}`
                      }))
                    }
                  ]}
                  options={{
                    placeholder: "select"
                  }}
                  value={props.formData.rightOptionCylinder}
                  onChange={props.formHandler}
                  name="rightOptionCylinder"
                />
              </td>
              <td className="extra-prism-td-label-centered">
                <LensDropDown
                  indexVal={1}
                  callback={props.formHandler}
                  listOptions={axisRange}
                  selectedOption={props.formData.rightOptionAxis}
                  name="rightOptionAxis"
                />
              </td>
              <td className="extra-prism-td-label-centered">
                <LensDropDown
                  indexVal={2}
                  callback={props.formHandler}
                  listOptions={addRange}
                  selectedOption={props.formData.rightOptionAdd}
                  name="rightOptionAdd"
                />
              </td>
            </tr>
            <tr>
              <th className="extra-prism-th-column-label-centered">OS (Left)</th>
              <td className="extra-prism-td-label-centered extra-prism-select2-td-label">
                <Select2
                  data={[
                    {
                      text: "0.00 / Plano / PL",
                      id: "0.00 / Plano / PL"
                    },
                    {
                      text: "-",
                      children: sphereNegativeRange.map((optval, i) => ({
                        text: optval,
                        id: optval
                      }))
                    },
                    {
                      text: "+",
                      children: spherePositiveRange.map((optval, i) => ({
                        text: `+ ${optval}`,
                        id: `+ ${optval}`
                      }))
                    },
                    {
                      text: "Balance / Bal",
                      id: "Balance / Bal"
                    }
                  ]}
                  options={{
                    placeholder: "select"
                  }}
                  value={props.formData.leftOptionSphere}
                  onChange={props.formHandler}
                  name="leftOptionSphere"
                />
              </td>
              <td className="extra-prism-td-label-centered extra-prism-select2-td-label">
                <Select2
                  data={[
                    {
                      text: "Blank / SPH / 0.00",
                      id: "Blank / SPH / 0.00"
                    },
                    {
                      text: "-",
                      children: cylinderNegativeRange.map((optval, i) => ({
                        text: optval,
                        id: optval
                      }))
                    },
                    {
                      text: "+",
                      children: cylinderPositiveRange.map((optval, i) => ({
                        text: `+ ${optval}`,
                        id: `+ ${optval}`
                      }))
                    }
                  ]}
                  options={{
                    placeholder: "select"
                  }}
                  value={props.formData.leftOptionCylinder}
                  onChange={props.formHandler}
                  name="leftOptionCylinder"
                />
              </td>
              <td className="extra-prism-td-label-centered">
                <LensDropDown
                  indexVal={3}
                  callback={props.formHandler}
                  listOptions={axisRange}
                  selectedOption={props.formData.leftOptionAxis}
                  name="leftOptionAxis"
                />
              </td>
              <td className="extra-prism-td-label-centered">
                <LensDropDown
                  indexVal={4}
                  callback={props.formHandler}
                  listOptions={addRange}
                  selectedOption={props.formData.leftOptionAdd}
                  name="leftOptionAdd"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="form-group">
          <span class="know-my-pd">I know my Pupil Distance (PD).</span> <span style={{cursor:'pointer'}} class="badge pd-info" data-toggle="modal" data-target="#pd_info_modal"> ? </span>
          <input type="checkbox" class="know-pd-checkbox" checked={props.formData.i_know_my_pd_rx} name="i_know_my_pd_rx" onChange={props.formHandler}  />
        </div>
        {props.formData.i_know_my_pd_rx == true && <div className="form-group pd-box">
          <div className="table-responsive table-resp-no-overflow table-two-pd remove-padding">
            <table className="table space">
              <th className="th-two-pd">
                <input
                  type="radio"
                  onChange={() => props.updateTwoPDNumber(false)}
                  checked={props.formData.twoPDNumber ? false : true}
                />
                <label
                  className="label-two-pd"
                  onClick={() => props.updateTwoPDNumber(false)}
                >
                  Single PD
                </label>
              </th>
              <th className="th-two-pd1">
                <input
                  type="radio"
                  onChange={() => props.updateTwoPDNumber(true)}
                  checked={props.formData.twoPDNumber ? true : false}
                />
                <label
                  className="label-two-pd"
                  onClick={() => props.updateTwoPDNumber(true)}
                >
                  Dual PD
                </label>
              </th>
              <tbody>
                <tr className="tr-two-pd">
                  <td className="td-two-pd">
                    <div className="pd1">
                      <div className="Rect1">
                        <LensDropDown
                          indexVal={5}
                          callback={props.formHandler}
                          listOptions={pupillaryRange}
                          selectedOption={props.formData.osCombined}
                          checked={
                            props.formData.twoPDNumber ? "disabled" : null
                          }
                          name="osCombined"
                          className="sel2"
                        />{" "}
                      </div>
                    </div>
                  </td>
                  <td className="td-two-pd">
                    <div className="pd1">{bothPDDropDown()}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> }

        
       
        <div className="form-group" style={{ color: "red" }}>
          {props.prescriptionError ? "Fields are Required" : ""}
        </div>
        <hr />
        <div className="extra-prism">
          I have Prism
          <span style={{cursor:'pointer'}} class="badge pd-info" data-toggle="modal" data-target="#prism_info_modal"> ? </span>
          <input
            type="checkbox"
            checked={props.formData.have_prism}
            className="extra-prism-box"
            name="have_prism"
            onChange={props.formHandler}
          />
        </div>

        {props.formData.have_prism && (
          <table className="table space">
            <thead>
              <tr>
                <th scope="col" className="extra-prism-th-row-label-centered"></th>
                <th scope="col" className="extra-prism-th-row-label-centered">
                  Prism Horizontal
                </th>
                <th scope="col" className="extra-prism-th-row-label-centered">
                  Base Direction
                </th>
                <th scope="col" className="extra-prism-th-row-label-centered">
                  Prism Vertical
                </th>
                <th scope="col" className="extra-prism-th-row-label-centered">
                  Base Direction
                </th>
                <th scope="col" className="extra-prism-th-row-label-centered"></th>
                <th scope="col" className="extra-prism-th-row-label-centered"></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th className="extra-prism-th-column-label-centered">OD (Right)</th>
                <td className="extra-prism-td-label-centered">
                  <LensDropDown
                    indexVal={6}
                    callback={props.formHandler}
                    listOptions={prism_values}
                    selectedOption={props.formData.right_prism_horizontal}
                    name="right_prism_horizontal"
                  />
                </td>
                <td className="extra-prism-td-label-centered">
                  <LensDropDown
                    indexVal={7}
                    callback={props.formHandler}
                    listOptions={base_direction_hori}
                    selectedOption={props.formData.right_prism_horizontal_bd}
                    name="right_prism_horizontal_bd"
                  />
                </td>
                <td className="extra-prism-td-label-centered">
                  <LensDropDown
                    indexVal={8}
                    callback={props.formHandler}
                    listOptions={prism_values}
                    selectedOption={props.formData.right_prism_vertical}
                    name="right_prism_vertical"
                  />
                </td>
                <td className="extra-prism-td-label-centered">
                  <LensDropDown
                    indexVal={9}
                    callback={props.formHandler}
                    listOptions={base_direction_ver}
                    selectedOption={props.formData.right_prism_vertical_bd}
                    name="right_prism_vertical_bd"
                  />
                </td>
              </tr>
              <tr>
                <th className="extra-prism-th-column-label-centered">OS (Left)</th>
                <td className="extra-prism-td-label-centered">
                  <LensDropDown
                    indexVal={10}
                    callback={props.formHandler}
                    listOptions={prism_values}
                    selectedOption={props.formData.left_prism_horizontal}
                    name="left_prism_horizontal"
                  />
                </td>
                <td className="extra-prism-td-label-centered">
                  <LensDropDown
                    indexVal={11}
                    callback={props.formHandler}
                    listOptions={base_direction_hori}
                    selectedOption={props.formData.left_prism_horizontal_bd}
                    name="left_prism_horizontal_bd"
                  />
                </td>
                <td className="extra-prism-td-label-centered">
                  <LensDropDown
                    indexVal={12}
                    callback={props.formHandler}
                    listOptions={prism_values}
                    selectedOption={props.formData.left_prism_vertical}
                    name="left_prism_vertical"
                  />
                </td>
                <td className="extra-prism-td-label-centered">
                  <LensDropDown
                    indexVal={13}
                    callback={props.formHandler}
                    listOptions={base_direction_ver}
                    selectedOption={props.formData.left_prism_vertical_bd}
                    name="left_prism_vertical_bd"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}
export default OnlinePresFields;
