

export const isEmpty=(obj)=>{
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

          export const sphereRange= Array.from({length: 225}).map((_, i) => (-28 + i*0.25).toFixed(2))

          export const spherePositiveRange= Array.from({length: 80}).map((_, i) => (0.25 + i*0.25).toFixed(2))

          export const sphereNegativeRange= Array.from({length: 112}).map((_, i) => (-28 + i*0.25).toFixed(2)).reverse()

          export const cylinderRange= Array.from({length: 97}).map((_, i) => (-12 + i*0.25).toFixed(2))

          export const cylinderPositiveRange= Array.from({length: 48}).map((_, i) => (0.25 + i*0.25).toFixed(2))

          export const cylinderNegativeRange= Array.from({length: 48}).map((_, i) => (-12 + i*0.25).toFixed(2)).reverse()

          export const axisRange= ['0 / Blank'].concat(Array.from({length: 180}).map((_, i) => (1 + i*1)));


          export const addRange= Array.from({length: 21}).map((_, i) => (i === 0 ? "+0.00 / Blank" : `+${(0.0 + i*0.25).toFixed(2)}`));

          export const pupillaryRange= Array.from({length: 111}).map((_, i) => (30 + i*0.5).toFixed(2))

          export const bothPupillary= Array.from({length: 57}).map((_, i) => (15 + i*0.5).toFixed(2))

  export const base_direction_hori= ['In','Out']
  export const base_direction_ver= ['Up','Down']

  export const prism_values= Array.from({length: 41}).map((_, i) => (i === 0 ? "0.00 / Blank" : (0 + i*0.25).toFixed(2)));
  

export const singlePdRange= Array.from({length: 111}).map((_, i) => (30 + i*0.5).toFixed(2));

export const doublePdRange= Array.from({length: 57}).map((_, i) => (15 + i*0.5).toFixed(2));


export const currencySymbol=(symbol)=>{
 return symbol=='USD'? '$' : '$'
} 

export const MultiPurposeList= ["Light-Adjusting (Photochromic)","Bifocal","Blue Light + Light Adjusting (Photochromic)"];
export const lensFeatureList=["Clear","Blue Light","Sunglass","Fashion"];
export const stringInArray=(list,selected)=>{ return list.find(function(element) { return selected.includes(element)})? true : false };
export const showLensTypeLabel=(stepName)=>{  
    if(stepName=='Prescription Type')
    {

      return 'B';
    }
    else if(stepName=='Feature')
    {
      return 'P';
    }
    else
    return '';
   }
export const errorHandling=(errors,error,state)=>{
  let errorMsg='';
  switch(state){

    case "address": errorMsg='Address is invalid'
    break;
    case "delivery": errorMsg= error;
    break;
    case "payment": errorMsg= errors.constructor== Object? Object.values(errors)[0].join(", ") : error
    break;
    case "confirm": errorMsg= error;
    break;
    case "promo": errorMsg= error;
    break;
  }
  // errors=(errors && errors.constructor!== Object)?Object.values(errors)[0]: []
  // let errorMsg='';
  // if(errors.length>0)
  // {
  //   errorMsg= errors.join(', ');
  // }
  // else
  // {
  //   errorMsg= error;//Object.values(error).join(', ');
    
  // }
  return errorMsg;
}
export const payloadAddressObj=(address,user_id)=>{
  return  {
    "firstname": address.firstName,
    "lastname": address.lastName,
    "address1": address.address,
    "address2": address.addressOne,
    "city": address.city,
    "phone": address.phone,
    "zipcode": address.zipcode,
    "state_id": address.stateId,
    "country_id": address.countryId,
    "user_id": user_id
}
}


export const addressPayloadBuild = (billaddressAttr,billAddId,changesInBillingForm,shipAddressAttr,shipAddId,changesInShippingForm,isSameAddress,isTextOptIn) => {
     

     if(isSameAddress) {
       if(changesInBillingForm){
          return {
            bill_address_attributes: billaddressAttr,
            ship_address_attributes: billaddressAttr,
            use_billing: 1,
            isTextOptIn: isTextOptIn
          }
       }else{
          return {
            bill_address_id: billAddId,
            ship_address_id: billAddId,
            use_billing: 1,
            isTextOptIn: isTextOptIn
          }
       }
      
     }else {
      if(changesInBillingForm && changesInShippingForm){
        return {
          bill_address_attributes: billaddressAttr,
          ship_address_attributes: shipAddressAttr,
          use_billing: 0,
          isTextOptIn: isTextOptIn
        }
      }
        else if(!changesInBillingForm && changesInShippingForm){
          return {
            bill_address_id: billAddId,
            ship_address_attributes: shipAddressAttr,
            use_billing: 0,
            isTextOptIn: isTextOptIn
          }
        } else if(changesInBillingForm && !changesInShippingForm){
          return {
            bill_address_attributes: billaddressAttr,
            ship_address_id: shipAddId,
            use_billing: 0,
            isTextOptIn: isTextOptIn
          }
        }
     else{
        return {
          bill_address_id: billAddId,
          ship_address_id: shipAddId,
          use_billing: 0,
          isTextOptIn: isTextOptIn
        }
     }
     }
}


export const prescriptionValidation=(data,prescriptionTypeId, prescriptionId)=>{
  let invalid=false;
  switch(prescriptionTypeId)
  {
    // case 1: invalid= data.osLeft=="" || data.osRight=="";
    // break;
    case 2: invalid=  Object.keys(data.pdImage).length === 0 && data.pdImage.constructor === Object;
    break;
    case 3: invalid=  data.prescriptionName === '';//data.rightOptionCylinder==''|| data.rightOptionAxis=='' || data.rightOptionAdd == '' ||data.leftOptionSphere==''||data.rightOptionSphere==''||data.leftOptionCylinder==''|| data.rightOptionCylinder==''||data.leftOptionAxis=='' || data.leftOptionAdd == '' ;
    break;
    case 4: invalid= data.dPhoneNumber === "" || data.dName === "" || data.dPatientName === '' || data.dPatientDOB === '';
      break;
  }
  return invalid;

}

export const hexToRGBOpacity = (hex,group,opacity,disableGradient) => {
  hex = '0x' + hex.replace('#','');
  let r = hex >> 16 & 0xFF
  let g = hex >> 8 & 0xFF
  let b = hex & 0xFF
  group=group.toLocaleLowerCase();
  if(disableGradient)
  return `rgb(${r}, ${g}, ${b}, ${opacity})`;
  if(group.includes('polarized & mirror finish'))
  return `radial-gradient(circle at top, rgba(${r}, ${g}, ${b},.1) 22%,rgba(${r}, ${g}, ${b},.5) 53%,rgba(${r}, ${g}, ${b},1) 100%)`;
  else if(group.includes('polarized'))
  return `linear-gradient(to bottom, rgba(${r}, ${g}, ${b}, 1)  35%,rgba(${r}, ${g}, ${b},.4) 87%)`;
  else if(group.includes('gradient'))
  return `linear-gradient(to bottom, rgba(${r}, ${g}, ${b}, 1)  37%,rgb(${r}, ${g}, ${b}, ${opacity}) 85%)`;
  else 
  return `rgb(${r}, ${g}, ${b}, ${opacity})`;
  // return linear-gradient(to bottom, rgba(${r}, ${g}, ${b}, 1) 30%,rgba(${r}, ${g}, ${b},.6) 70%);
  // return radial-gradient(ellipse at top, rgba(${r}, ${g}, ${b},1) 17%,rgba(${r}, ${g}, ${b},1) 53%,rgba(${r}, ${g}, ${b},1) 20%);
}

export const prescriptionBody=(variandId,selPresTypeId,presTypeId,presId,formData)=>{

    const prescription_correction= {
        "right":formData.osLeft,
        "left":formData.osRight
      };

      let colorIntensity = 0;
      if(formData.texture != 'Solid Tint') {
         colorIntensity = formData.colorIntensity * 100
      } else {
         colorIntensity = formData.solidcolorIntensity * 100
      }



      
      let data={};
      console.log(presId, presTypeId, selPresTypeId);
      if(presId){
        switch(presTypeId){
            case 2:
            data= {"line_item":  {
                        "variant_id": variandId,
                        "quantity": 1,
                        "prescription": {
                          "prescription_input_type": presTypeId,
                          "user_prescription_image_id": presId,
                        },
                        "prism_correction":prescription_correction,
                        "user_comment":formData.comment,
                        warranty_id: formData.warrantyID,
                        color_id: formData.colorId,
                        color_intensity_value: colorIntensity 
                       // color_type_id: formData.color_type_id
                       // "user_frames":formData.frames
                        }
                    }
                    
                    break;
            case 3:
            data= {"line_item":  
                      {
                        "variant_id": variandId,
                        "quantity": 1,
                        "prescription": {
                          "prescription_input_type": presTypeId,
                          "user_prescription_detail_id": presId,
                        },
                        "prism_correction":prescription_correction,
                        "user_comment":formData.comment,
                        warranty_id: formData.warrantyID,
                        color_id: formData.colorId,
                        color_intensity_value: colorIntensity
                        //color_type_id: formData.color_type_id
                       // "user_frames":formData.frames
                      }}
                    break;
           /*case 4:
              switch(selPresTypeId) {
                case 2:
                  data={
                    line_item: {
                      variant_id: variandId,
                      quantity: 1,
                      prescription: {
                        prescription_input_type: selPresTypeId,
                        user_prescription_image_id: presId,
                      },
                      prism_correction:prescription_correction,
                      user_comment:formData.comment,
                      warranty_id: formData.warrantyID,
                      color_id: formData.colorId,
                      color_intensity_value: colorIntensity
                    }
                  }
                  break;
                case 3:
                  data={
                    line_item: {
                      variant_id: variandId,
                      quantity: 1,
                      prescription: {
                        prescription_input_type: selPresTypeId,
                        user_prescription_detail_id: presId,
                      },
                      prism_correction:prescription_correction,
                      user_comment:formData.comment,
                      warranty_id: formData.warrantyID,
                      color_id: formData.colorId,
                      color_intensity_value: colorIntensity
                    }
                  }
                  break;
                case 4:
                  data={
                    line_item: {
                      variant_id: variandId,
                      quantity: 1,
                      prescription: {
                        prescription_input_type: selPresTypeId,
                        doctor_id: presId,
                      },
                      prism_correction:prescription_correction,
                      user_comment:formData.comment,
                      warranty_id: formData.warrantyID,
                      color_id: formData.colorId,
                      color_intensity_value: colorIntensity
                    }
                  }
                  break;
              }
              break;*/
          case 4:
            data={"line_item":  {
                "variant_id": variandId,
                "quantity": 1,
                "prescription": {
                  "prescription_input_type": 4,
                  "name":formData.dName,
                  "phone":formData.dPhoneNumber,
                  "patients_name":formData.dPatientName,
                  "patients_dob":formData.dPatientDOB
                },
                "prism_correction":prescription_correction,
                "user_comment":formData.comment,
                warranty_id: formData.warrantyID,
                color_id: formData.colorId,
                color_intensity_value: colorIntensity
                //color_type_id: formData.color_type_id
                //  "user_frames":formData.frames
              }
            }
            break;
                }

    }
      else{
      
        switch(presTypeId){
            case 1:
            data= {"line_item":  { 
                        "variant_id": variandId,
                        "quantity": 1,
                        "prescription": {
                        "prescription_input_type": 1
                    },
                    "prism_correction":prescription_correction,
                    "user_comment":formData.comment,
                    warranty_id: formData.warrantyID,
                    color_id: formData.colorId,
                    color_intensity_value: colorIntensity
                    //color_type_id: formData.color_type_id
                   // "user_frames":formData.frames
                }
            }
            break;
            case 2:
                data= {"line_item":  { 
                    
                            "variant_id": variandId,
                            "quantity": 1,
                            "prescription": {
                            "prescription_input_type": 2,
                            "image":formData.pdImage,
                           // "is_pd_on_prescription":formData.PDList
                             },
                            "prism_correction":prescription_correction,
                            "user_comment":formData.comment,
                            warranty_id: formData.warrantyID,
                            color_id: formData.colorId,
                            color_intensity_value: colorIntensity
                            //color_type_id: formData.color_type_id
                           // "user_frames":formData.frames
                            }
                        }
                        break;
            case 3:
                data= {"line_item":  {
                    "variant_id": variandId,
                    "quantity": 1,
                    "prescription": {
                    "prescription_name":formData.prescriptionName,
                    "prescription_input_type": 3,
                    "right_eye_sphere":formData.rightOptionSphere,
                    "right_eye_cylinder":formData.rightOptionCylinder,
                    "right_eye_axis":formData.rightOptionAxis,
                    "right_eye_add":formData.rightOptionAdd,
                    "left_eye_sphere":formData.leftOptionSphere,
                    "left_eye_cylinder":formData.leftOptionCylinder,
                    "left_eye_axis":formData.leftOptionAxis,
                    "left_eye_add":formData.leftOptionAdd,
                    "i_know_my_pd_rx":formData.i_know_my_pd_rx,
                    "has_two_pd":formData.twoPDNumber,
                    "pd_left":formData.osLeft,
                    "pd_right":formData.osRight,
                    "pd_combined":formData.osCombined
                    },
                    "prism_correction":{
                      "right_prism_horizontal":formData.right_prism_horizontal,
                      "right_prism_horizontal_bd":formData.right_prism_horizontal_bd,
                      "right_prism_vertical":formData.right_prism_vertical,
                      "right_prism_vertical_bd":formData.right_prism_vertical_bd,

                      "left_prism_horizontal":formData.left_prism_horizontal,
                      "left_prism_horizontal_bd":formData.left_prism_horizontal_bd,
                      "left_prism_vertical":formData.left_prism_vertical,
                      "left_prism_vertical_bd":formData.left_prism_vertical_bd
                    },
                    "user_comment":formData.comment,
                    warranty_id: formData.warrantyID,
                    color_id: formData.colorId,
                    color_intensity_value: colorIntensity
                    //color_type_id: formData.color_type_id
                   // "user_frames":formData.frames
                }
                    }
                        break;
            case 5:
              data={
                line_item: {
                  variant_id: variandId,
                  quantity: 1,
                  prescription: {
                    prescription_input_type: presTypeId,
                  },
                  prism_correction:prescription_correction,
                  user_comment:formData.comment,
                  warranty_id: formData.warrantyID,
                  color_id: formData.colorId,
                  color_intensity_value: colorIntensity
                }
              }
          case 4:
            data={"line_item":  {
                "variant_id": variandId,
                "quantity": 1,
                "prescription": {
                  "prescription_input_type": 4,
                  "name":formData.dName,
                  "phone":formData.dPhoneNumber,
                  "patients_name":formData.dPatientName,
                  "patients_dob":formData.dPatientDOB
                },
                "prism_correction":prescription_correction,
                "user_comment":formData.comment,
                warranty_id: formData.warrantyID,
                color_id: formData.colorId,
                color_intensity_value: colorIntensity
                //color_type_id: formData.color_type_id
                //  "user_frames":formData.frames
              }
            }
            break;
            }
        }

    if (presTypeId == 0 && presId == 0) {
        data={"line_item":  { 
                    "variant_id": variandId,
                    "prescription":{
                        "prescription_input_type":1
                    },
                    "quantity": 1,
                    "user_comment":formData.comment,
                    warranty_id: formData.warrantyID,
                    color_id: formData.colorId,
                    color_intensity_value: colorIntensity
                    //color_type_id: formData.color_type_id
                   // "user_frames":formData.frames
                }
                }
    }
    console.log(data);
    return data;
}
