import React, { Component} from 'react';
import { SelectOption } from './SelectOption';
import TextField from './TextField';

class AddressForm extends Component {

    componentWillReceiveProps = (nextProps) => { // Load Contact Asynchronously
      // const { address } = nextProps;
      // if(address.id !== this.props.address.id) { // Initialize form only once
      //   this.props.initialize(address);
      // }
    }
  
    
  
    render() {
      const { addressList,address,countryList,initial,stateList,addressName,formHandler,changeStateList,validation,fillAddress} = this.props;
      
      return (
        <div className="single-page-checkout-address">
          <div className="col-sm-12">
            <h1 className="heading-cart-section">{addressName}</h1>
            <form>
              {addressList.length>0 && <SelectOption  selectedOption={address.addressId} inValid={false} callback={fillAddress} errorMsg={''} listOptions={addressList} name="addresses" value={address.id} target={"addressList"}  label="Select Address"/>}
              {/* <TextField formHandler={formHandler} name="email"  type="text" inValid={initial||validation.email.isInvalid} errorMsg={validation.email.message} value={address.email} placeholder="john@doe.com" label="Customer E-Mail"/> */}
              <TextField formHandler={formHandler} name="firstName"  type="text" inValid={initial||validation.firstName.isInvalid} errorMsg={validation.firstName.message} value={address.firstName} placeholder="John" label="First Name"/>
              <TextField formHandler={formHandler} name="lastName"  type="text" inValid={initial||validation.lastName.isInvalid} errorMsg={validation.lastName.message} value={address.lastName} placeholder="Doe" label="Last Name"/>
              <TextField formHandler={formHandler} name="address"  type="text" inValid={initial||validation.address.isInvalid} errorMsg={validation.address.message} value={address.address} placeholder="enter address" label="Address"/>
              <TextField formHandler={formHandler} name="addressOne"  type="text" inValid={false} placeholder="Address cntd." value={address.addressOne} label="Address Cont."/>
              <TextField formHandler={formHandler} name="city"  type="text" inValid={initial||validation.city.isInvalid} errorMsg={validation.city.message} value={address.city}  placeholder="enter city" label="City"/>
              <SelectOption selectedOption={address.countryId} inValid={initial||validation.countryId.isInvalid} callback={changeStateList} errorMsg={validation.countryId.message} listOptions={countryList} name="countryId" label="Country"/>
              <SelectOption selectedOption={address.stateId} inValid={initial||validation.stateId.isInvalid} callback={formHandler} errorMsg={validation.stateId.message} listOptions={stateList} name="stateId" label="State"/>
              <TextField formHandler={formHandler} name="zipcode"  type="text" inValid={initial||validation.zipcode.isInvalid} errorMsg={validation.zipcode.message} value={address.zipcode} placeholder="00000" label="Zipcode"/>
              <TextField formHandler={formHandler} name="phone"  type="text" inValid={initial||validation.phone.isInvalid} errorMsg={validation.phone.message} value={address.phone} placeholder="123-456-7890" label="Phone Number"/>
              
              
            </form>
          </div>
        </div>
      );
    }
  }
  
  export default AddressForm;
