import React from "react";


const CheckoutProgress=({order,changeState})=>{

return(<div className="col-sm-9 progress_custom"  style={{"color":"black"}}>
<ul className="progress-steps nav nav-pills nav-justified" id="checkout-step-delivery">
{order.checkout_steps.map((x,key)=>(
<li key={key} className={"checkout-tab-zig "+ (order.checkout_steps.indexOf(order.state)>=key?'active':'disable')}>
      <a href="#" className="checkout-tab-ahover" onClick={()=>changeState(x)}>{x == 'delivery'? 'Shipping':x}</a>
      {order.checkout_steps.length -1 != key && <i  className={"fas fa-chevron-right checkout-tab "+ (order.checkout_steps.indexOf(order.state)>=key?'active':'disable')}></i>}
</li>
))}
</ul>
</div>);
}

export default CheckoutProgress;