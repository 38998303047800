import React from "react";
export const  PayPalCheckout=(props)=> {
    return (
      <div class="paypal-button-wrapper">
      <a id="paypal_button" rel="nofollow" data-method="post" href={"/paypal?payment_method_id="+props.id} >
      <img src="/paypal/pay_with_paypal.png"/>
      </a>
      </div>
       
    )
  }
  export default  PayPalCheckout;