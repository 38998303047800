

import React from "react";
import PropTypes from "prop-types";
export const LineItems=(props)=>{
    
return(<table className="table-order-summary-left">
    <thead>
              <tr className="active">
                
                <th className="cart-item-description-header">Item</th>
                <th className="cart-item-description-header price ">Price</th>
              </tr>
            </thead>
<tbody>
    {props.items.map((x,key)=> (
        <tr key={key} className={""}>
            <td className="line-item">
              <p>{x.name}</p> 
              {x.options_text.split(",").map((option) => {
               return <p style={{"marginBottom": "0","width":"100%","text-align":"left"}}>{option}</p>
              })}
            </td>
            <td className="line-amount">{x.display_amount}</td>
           
            </tr>))}
            </tbody>
            </table>);
}

export default LineItems;