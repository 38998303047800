import React from "react";
import PropTypes from "prop-types";
class LensStaging extends React.Component {
  render () {
const {position,type}=this.props;
let stepList = Object.assign([],type);
stepList.unshift({id:0,presentation: "Lens Type"});   

 
const listElements=()=>{
  return stepList.map((item,key)=><div className={"order-bar-"+(key+1==position?"active":"inactive")} onClick={()=>this.props.callback(key+1)} key={key} > 
  <a href="javascript:void">
    <div className={"oval"+(key+1==position?"-active":"")}>
      <div className={"order-bar-number"+(key+1==position?"-active":"")}>{key+1}</div>
    </div>
    <div className="order-bar-text">
      {item.presentation}
    </div>
    {/* <div className="order-bar-line"></div> */}
  </a>
  </div>);
}

    return (
      <div className="order-bar">{listElements()}</div>
    );
  }
}

LensStaging.propTypes = {
  position: PropTypes.number,
  type: PropTypes.array
};
export default LensStaging;
