import React, { Component } from "react";
import { bothPupillary, pupillaryRange } from "../extensions/extensions";
import PDPopup from "../components/PDPopup";

class MeasurementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      currentPopUpLineItem: "",
      firstPDItem: null
    };
  }

  togglePopupCurrent(id) {
    this.setState({
      currentPopUpLineItem: id,
      showPopup: !this.state.showPopup
    });
  }

  togglePopupChild() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  findFirstPdItem() {
    
  }

  render() {
    const {
      lineItems,
      formHandler,
      measurementForm,
      imageHandler,
      isSamePd
    } = this.props;

    
    if (
      Object.keys(measurementForm).length === 0 ||
      Object.keys(measurementForm).length != lineItems.length
    ) {
      return "";
    }

    const renderItems = (items) => {

      let firstPDItem = null
      this.props.lineItems.map((item) =>{
       if (item.show_measurement_form == true){
         if (firstPDItem == null) {
           firstPDItem= item.id
         }
       }
      });

      const renderPD = (item) => {
        return (
          <>
            <div class={`panel-group toggle-measurement-checkout ${(item.id != firstPDItem && this.props.isSamePd)  ? 'pd_measurement_disable' : ''}`}>
              <div class={`panel panel-default ${(item.id != firstPDItem && this.props.isSamePd)  ? 'pd_measurement_disable' : ''}`}>
                <div class="panel-heading toggle-measurement-checkout-heading">
                  <h4 class="panel-title">
                    <a
                      data-toggle="collapse"
                      class=" toggle-measurement-checkout-heading-a"
                      href={`#${item.id}_renderPDParent`}
                    >
                      Pupillary distance
                    </a>
                  </h4>
                </div>
                <div
                  id={`${item.id}_renderPDParent`}
                  class="panel-collapse collapse"
                >
                  {measurementForm[item.id].pd_calculated == null ? (
                    <div className="form-group pd-box">
                      <div className="table-responsive table-resp-no-overflow table-two-pd checkout-measurement">
                        <table className="table space">
                          <th className="th-two-pd mesurement-pd-label">
                            <input
                              type="radio"
                              onClick={e => {
                                formHandler(item.id, false, "has_two_pd");
                              }}
                              checked={
                                measurementForm[item.id].has_two_pd
                                  ? false
                                  : true
                              }
                            />
                            <label
                              className="label-two-pd"
                              onClick={e => {
                                formHandler(item.id, false);
                              }}
                            >
                              Single PD
                            </label>
                          </th>
                          <th className="th-two-pd mesurement-pd-label">
                            <input
                              type="radio"
                              onClick={() => {
                                formHandler(item.id, true, "has_two_pd");
                              }}
                              checked={
                                measurementForm[item.id].has_two_pd
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="label-two-pd"
                              onClick={() => {
                                formHandler(item.id, true, "has_two_pd");
                              }}
                            >
                              Dual PD
                            </label>
                          </th>
                          <tbody>
                            <tr className="tr-two-pd">
                              <td className="td-two-pd">
                                <div className="pd1">
                                  <div className="Rect1">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        value={
                                          measurementForm[item.id].pd_combined
                                        }
                                        onChange={e => {
                                          formHandler(
                                            item.id,
                                            e.target.value,
                                            "pd_combined"
                                          );
                                        }}
                                        name="pd_combined"
                                        disabled={
                                          measurementForm[item.id].has_two_pd
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="">select</option>
                                        {pupillaryRange.map(option => (
                                          <option value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
                                    </div>{" "}
                                  </div>
                                </div>
                              </td>

                              <td className="td-two-pd">
                                <div className="pd1">
                                  <div>
                                    <div className="Rect1">
                                      <div className="form-group">
                                        <select
                                          className="form-control"
                                          value={
                                            measurementForm[item.id].pd_left
                                          }
                                          onChange={e => {
                                            formHandler(
                                              item.id,
                                              e.target.value,
                                              "pd_left"
                                            );
                                          }}
                                          name="pd_left"
                                          disabled={
                                            measurementForm[item.id].has_two_pd
                                              ? false
                                              : true
                                          }
                                        >
                                          <option value="">select</option>
                                          {bothPupillary.map(option => (
                                            <option value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="Rect1">
                                      <div className="form-group">
                                        <select
                                          className="form-control"
                                          value={
                                            measurementForm[item.id].pd_right
                                          }
                                          onChange={e => {
                                            formHandler(
                                              item.id,
                                              e.target.value,
                                              "pd_right"
                                            );
                                          }}
                                          name="pd_right"
                                          disabled={
                                            measurementForm[item.id].has_two_pd
                                              ? false
                                              : true
                                          }
                                        >
                                          <option value="">select</option>
                                          {bothPupillary.map(option => (
                                            <option value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="calculated-pd-measurement">
                      {measurementForm[item.id].pd_calculated}
                      <span
                        class="glyphicon glyphicon-remove-sign cart_remove_item"
                        onClick={() => {
                          formHandler(item.id, null, "pd_calculated");
                        }}
                      ></span>
                    </div>
                  )}
                  <div class="panel-footer">
                    If you don't know it, you can get it calculated- click below
                    <p>
                      <button
                        class="btn btn-primary button-calculate-pd-now"
                        onClick={() => {
                          this.togglePopupCurrent(item.id);
                        }}
                      >
                        Measure PD
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      };

      const renderSecondMea = item => {
        return (
          <div class="panel-group toggle-measurement-checkout">
            <div class="panel panel-default">
              <div class="panel-heading toggle-measurement-checkout-heading">
                <h4 class="panel-title ">
                  <a
                    data-toggle="collapse"
                    class=" toggle-measurement-checkout-heading-a"
                    href={`#${item.id}_renderSegmentHeight`}
                  >
                    Segment height image
                  </a>
                </h4>
              </div>
              <div
                id={`${item.id}_renderSegmentHeight`}
                class="panel-collapse collapse segment-height-measurement"
              >
                <a href="#" className="segment-height-measurement-a" data-toggle="modal" data-target="#segment_modal">
                  What is this ?
                </a>
                <input
                  type="file"
                  className="form-control-file segment-height-measurement-upload"
                  onChange={e => {
                    imageHandler(item.id, e);
                  }}
                  name="pdImage"
                />

                {item.height_image && (
                  <div class="panel-footer segment-height-measurement-footer">
                    <img
                      src={item.height_image}
                      className="img-prescription-lenses-type segment-height-measurement-image-uploaded"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      };

      return items.map(item => {
        return (
          <>
            <div>
              {item.options_text}

              {(item.show_measurement_form == false) ? (
                <p className="not-applicable"> Not Applicable </p>
              ) : (
                ""
              )}
              {item.show_measurement_form == true && renderPD(item)}
              {item.show_measurement_form == true && item.show_segment_height_form == true && renderSecondMea(item)}
            </div>
          </>
        );
      });
    };

    return (
      <>
        {lineItems && (lineItems.length > 1) && (lineItems.filter(item => item.show_measurement_form == true).length > 1)  && (<div> <input type="checkbox" checked={isSamePd} onChange={(e) => {formHandler(
                                              null,
                                              e,
                                              "isSamePd"
                                            );}} />Same PD for all</div>)}
        
        <div>{renderItems(lineItems)}</div>

        <div id="segment_modal" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 class="modal-title">What is segment height ?</h4>
              </div>
              <div class="modal-body">
                <p>
                  Segment Height, also known as Seg Height or SH, is the
                  vertical measurement in millimeters from the bottom of the
                  lens in your frames, to the beginning of the progressive
                  addition on a progressive lens, or the top line of a lined
                  bifocal.
                </p>
              </div>
            </div>
          </div>
        </div>

        {this.state.showPopup ? (
          <PDPopup
            text='Click "Close Button" to hide popup'
            closePopup={() => this.togglePopupChild()}
            lineItemId={this.state.currentPopUpLineItem}
            formHandler={formHandler}
          />
        ) : null}
      </>
    );
  }
}

export default MeasurementForm;
