import React from "react";
import Pop from './Pop';
import SegmentPop from './SegmentPop';

class Calculate extends React.Component {
    constructor() {
      super();
    
      this.state={
        showPopup: false,
        currentPopUpLineItem: '',
        firstPDItem: null
      }
    }

    togglePopupCurrent(id) {
        this.setState({
          currentPopUpLineItem: id,
          showPopup: !this.state.showPopup
        });
      }
    
      togglePopupChild() {
        this.setState({
          showPopup: !this.state.showPopup
        });
      }

    render() {
        return <>
        {this.props.type == 'pd'?
         <a href="#" onClick={() => this.togglePopupCurrent()} className="btn btn-primary rounded generate-label">Calculate PD</a>:
         <a href="#" onClick={() => this.togglePopupCurrent()} className="btn btn-primary rounded generate-label capture-segment-img">Capture Segment Height Image</a>
        }
         

          {this.state.showPopup ? (
            this.props.type == 'pd' ?
          <Pop
            text='Click "Close Button" to hide popup'
            closePopup={() => this.togglePopupChild()}
            lineItemId={this.props.line_item.id}
            orderNumber={this.props.orderNumber}
            itemCount={this.props.item_count}
          />:
          <SegmentPop
            text='Click "Close Button" to hide popup'
            closePopup={() => this.togglePopupChild()}
            lineItemId={this.props.line_item.id}
            orderNumber={this.props.orderNumber}
            itemCount={this.props.item_count}
          /> 

        ) : null}
        </>
    }
}

export default Calculate;