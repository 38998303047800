import React from "react";
import PropTypes from "prop-types";
import LensSelection from "./LensSelection";
import LensStaging from "./LensStaging";
import Summary from "./Summary";
import api from "./EndPoints";
import Comment from "./Comment";
import { addRange, prescriptionBody,prescriptionValidation } from "../extensions/extensions";
import LensColor from "./LensColor";

class ChooseLens extends React.Component {

    state={
        step:1,
        stepName:'',
        optionTypeID:0,
        accordianSize: 80,
        displayOtherBifocal:false,
        biFocalSelected:'',
        variantId:0,
        optionTypes:[],
        optionValues:[],
        others:false,
        linkedVariant:false,
        optionValueKey:'',
        selectedOption:1,
        showColors:false,
        warrantyOptions:[],
        summary:{ data:[],price:''},
        colorPrice: '0',
        prescriptionError:false,
        nestedSelected:{bifocal:'',photochromic:''},
        formData:{
        showBifocal:false,
        showPhotoChromicBlue:false,
        showPhotoChromic:false,
          frames:[],
          colorIntensity:.2,
          solidcolorIntensity:.8,
          showIntensityBar:false,
          sunglassImage:'',
          texture:'',
          colorHex:'',
          colorName:'',
          rightOptionSphere:'',
          colorId:'',
          rightOptionCylinder:'',
          prescriptionName:'',
          rightOptionAxis:'',
          rightOptionAdd:'',
          leftOptionSphere:'',
          leftOptionCylinder:'',
          leftOptionAxis:'',
          leftOptionAdd:'',
          twoPDNumber:false,
          warrantyID:null,
          PupillaryDistance:false,
          rightPupillaryDistance:'',
          leftPupillaryDistance:'',
          pdImage:{},
          comment:'',
          PDList:false,
          dPhoneNumber:'',
          dName:'',
          dPatientName:'',
          dPatientDOB:'',
          osLeft:'',
          osRight:'',
          osCombined:'',
          have_prism:false,
          right_prism_horizontal:'',
          right_prism_horizontal_bd:'',
          right_prism_vertical:'',
          right_prism_vertical_bd:'',
          left_prism_horizontal:'',
          left_prism_horizontal_bd:'',
          left_prism_vertical:'',
          left_prism_vertical_bd:'',
          i_know_my_pd_rx: false
          },
        prescriptionType:0,
        prescriptionId:0,
        selectedPrescriptionType:0,
        isModalActive:false,
        isTabImage:true,
        isPresc:false,
        isdctr:false,
        user:false,
        error:false,
        checkoutButtonDisable:false,
        showPrescriptionAdditionalOptions:false,
    };
    componentWillMount(){
      //console.log(this.props);
      window.addEventListener('resize', this.handleWindowSizeChange);
      const {user,stage,option_values,option_type_id,option_types,summary}=this.props.navigatedItem;
      this.setState({user: user, step: stage,optionTypes:option_types, optionValues: option_values,optionTypeID: option_type_id,summary:summary});
      const bifocalObj=summary.data.find(x=> x.name.includes('Bifocal') && (x.name.includes('Round') || x.name.includes('Top')) );
      let photochromicObj=summary.data.find(x=> x.name.includes('Photochromic'));
      if(photochromicObj)
      {
        photochromicObj=photochromicObj.name + photochromicObj.name.includes('Blue Light')?'PB':'P';
      }
      this.setState({nestedSelected:{...this.state.nestedSelected,bifocal: bifocalObj? bifocalObj.name : 'Bifocal Flat Top',photochromic: photochromicObj? photochromicObj.name : '' }});
    }
    componentDidMount(){
    
      if(!this.state.linkedVariant)
        api.LensServices.getOptionTypes()
        .then(response => {
          const {option_types,summary}=response.data;
            summary.data.splice(this.state.step); 
          this.setState({ optionTypes: option_types, summary: summary });
          //,optionValues: option_values 
        })
        .catch(function (error) {
          //console.log(error);
        });
     
    }


    //for handling different sizes and scrolling to top of the category as per user selection
    handleWindowSizeChange=()=>{
      this.setState({ accordianSize: window.innerWidth/15});
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }


    warrantyChanges=(id)=>{
      this.setState({formData:{...this.state.formData,warrantyID:id.toString()}});
    }

    closeModal=()=>{
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      this.setState({isModalActive:false});
    }

    selectSavedPrescription=(selectedtab)=>{
      this.setState({isTabImage: false,isPresc:false,isdctr:false});
      this.selectedSaved(selectedtab);
    }

    selectedSaved=(selectedtab)=>{
      this.setState({[selectedtab] : true});
    }

      selectedStep=(step)=>{
        if (this.state.step > step){
          const {optionTypes}=this.state;
          if(step == 1){
            this.setState({step: 1 });
          }
          else{

            if(step>1)
            if(optionTypes[step-2].name == "comment")
          {
            api.LensServices.getVariant().then(resp=>{
              const {variant_id,warranty,summary}=resp.data;
              this.setState({variantId: variant_id,warrantyOptions: warranty,summary:summary});
            }).catch((error) => {
                //console.log(error);
            });
          }

            const optionTypeID=optionTypes[step == 1 ? 0 : step-2].id;

            api.LensServices.getOptionValues({tid: optionTypeID})
            .then(response => {
              const {option_values,others}=response.data;
              this.setState({ optionValues: option_values, others: others,showColors: false, stepName: optionTypes[(optionTypes.length < step)? step-1 : step-2].presentation,step: step,optionTypeID: optionTypeID,optionValueKey: ''});
            });

          }
        }
      }

      dateHandler=(date)=>{
        this.setState({ formData: { ...this.state.formData, dPatientDOB:date}});
      }

      nestedSelection=async(name,type)=>{
        const {optionTypeID,optionValues}=this.state;
        const optionValue= optionValues.find(x=>x.name == name);
         api.LensServices.getOptionTypes({tid: optionTypeID,o_vid: optionValue.id})
        .then(async(response) => {
          const {summary}= response.data;
          summary.data.splice(this.state.step);
         this.setState({error: false,  summary: summary ,isModalActive:false,showColors: false });
        if(type == 'B')
         this.setState({nestedSelected:{...this.state.nestedSelected,bifocal:name}});
        else if(type == 'P'||type == 'PB')
        {
        const keyId=  type == 'P'? optionValues.findIndex(x=>x.name == 'Light-Adjusting (Photochromic) Gray'): optionValues.findIndex(x=>x.name == "Blue Light + Light Adjusting (Photochromic) Gray")
        this.setState({nestedSelected:{...this.state.nestedSelected,photochromic:type+name},optionValueKey: keyId});
        }
        });
      }

      showDualElement=(id,optionValueKey,type)=>{
        const formData=this.state.formData;
        this.setState({error: false,  optionValueKey:optionValueKey,
          formData: {...formData,
          showBifocal: type == 'B'? true : false}

        });

        if(type == 'PB'){
        this.setState({  formData: {...formData,showPhotoChromicBlue:true,showPhotoChromic:false}});
        }
        else if(type == 'P' ){
        this.setState({  formData: {...formData,showPhotoChromicBlue:false,showPhotoChromic:true  }});
        }
        api.LensServices.getOptionTypes({tid: this.state.optionTypeID,o_vid: id})
        .then(response => {
          const {summary}= response.data;
            summary.data.splice(this.state.step);
          this.setState({summary: summary});
        });

       // this.setState({summary: summary});
      }

      categorySelection=(productId,optionValueKey,model=false,otherNestedItem='')=>{

        let cstep = this.state.optionTypes
        let cstate = this.state.step
        let currentStep = cstep[cstate - 2]


        if(otherNestedItem==='S'){
          this.setState({nestedSelected:{...this.state.nestedSelected,bifocal:''}});
        }
        // else if(otherNestedItem==='B'){
        //   this.setState({nestedSelected:{...this.state.nestedSelected,bifocal:''}});
        // }
        // else if(otherNestedItem == 'P'){
        //   this.setState({nestedSelected:{...this.state.nestedSelected,photochromic:''}});
        // }


        if(this.state.step == 1){
          api.LensServices.getOptionTypes({pid: productId,tid: 0,isModalActive:model})
          .then(response => {
            const {option_types,summary}=response.data;
              summary.data.splice(this.state.step);
            this.setState({ error: false, optionTypes: option_types, summary: summary,optionValueKey: optionValueKey,isModalActive:model});
          })
        }
        else
        { const {optionValues,formData}=this.state;
          if(optionValues.length>0)
          {
            if(this.state.others){
              this.setState({ prescriptionType: optionValueKey+1});
             }

            this.setState({error: false,prescriptionError:false, showColors: optionValues[optionValueKey].name == 'Sunglass',isModalActive:model});
            api.LensServices.getOptionTypes({tid: this.state.optionTypeID,o_vid: productId})
            .then(response => {
              const {summary,option_types}= response.data;
                summary.data.splice(this.state.step);
              this.setState({error: false,optionTypes:option_types,  optionValueKey:optionValueKey, summary: this.state.summary });
              switch(currentStep.name) {
                case 'prescription_type':
                  this.setState({ summary: summary });
                  if(!optionValues[optionValueKey].name.includes('Bifocal')) {
                    this.setState({displayOtherBifocal: false})
                  }
                  break;
                case 'feature':
                  if (optionValues[optionValueKey].name == 'Sunglass') {
                    const {texture, colorName} = formData;
                    if (texture === '' && colorName === '') {
                      const typeWithColor = this.props.lens_color;
                      if (typeWithColor.length > 0) {
                        const colorType = typeWithColor[0]
                        const color = colorType.colors[0];

                        this.selectedColor(color.id, color.color_one, colorType.price, colorType.type, color.image, color.name);
                        this.sendSunglassToSession(color.id, colorType.type, color.name);
                      }

                    }
                  } else {
                    this.setState({summary: summary, formData: {...this.state.formData, texture: '', colorName: ''}});
                  }
                  break;
                default:
                  this.setState({ summary: summary });
                  break;
              }

              //close nested option types
              if(currentStep.name == 'prescription_type' && this.state.formData.showBifocal == true && !optionValues[optionValueKey].name.includes('Bifocal')){
                this.setState({summary: summary,formData: {...this.state.formData, showBifocal: false }});
              }else if(currentStep.name == 'feature' && (this.state.formData.PhotoChromicBlue == true || this.state.formData.showPhotoChromic == true)){
                this.setState({summary: summary,formData: {...this.state.formData, showPhotoChromicBlue: false,showPhotoChromic: false  }});
              }


            });
        }
       }

      }

      sendSunglassToSession=(cId,texture,colorName)=>{

        const label= `${texture} ${colorName?"("+colorName+")": ""}`;
                api.LensServices.sunglassLabel({label:label,colorId: cId}).then((resp)=>{
                const {summary}=resp.data;
                this.setState({summary:{...this.state.summary, price: summary.price, data: summary.data} });
                });
      }

      nextStep=()=>{
        const {optionTypes,step,optionValueKey,summary,formData,prescriptionType,prescriptionId,optionValues}=this.state;
        const screenWidth=window.innerWidth;
        this.setState({ accordianSize: screenWidth<=500? screenWidth/15 : screenWidth/10}, () => {  window.scrollTo(0,(step*113 + this.state.accordianSize)); })
        if(optionValueKey === "" && summary.data.length<step  && optionValues.length>0 && optionTypes[step - 2].name != 'lens_coating')
        {
          this.setState({error:true});
        }

        else{
          const optionTypeID=optionTypes[step-1].id;
          let errorPrescription=false;
          if(optionTypes[((step < 2)? 2 : step) - 2].name == 'prescription' && prescriptionId == 0)
        {
          errorPrescription= prescriptionValidation(formData,prescriptionType, prescriptionId);
          this.setState({prescriptionError: errorPrescription});
        }

        if(!errorPrescription)
        {

          //this.setState({error:false,step: step+1,optionTypeID: optionTypeID,optionValueKey: '',others: false, stepName: optionTypes[step-1].presentation });
          // const vale = this.state.optionValueKey);
          if(optionTypes[step-1].name == "comment")
          {
            api.LensServices.getVariant().then(resp=>{
              const {variant_id,warranty,summary}=resp.data;
              this.setState({variantId: variant_id,warrantyOptions: warranty,summary:summary,error:false,step: step+1,optionTypeID: optionTypeID,optionValueKey: '',others: false, stepName: optionTypes[step-1].presentation });
            }).catch((error) => {
                //console.log(error);
            });
          }
          api.LensServices.getOptionValues({tid: optionTypeID})
          .then(response => {
            const {option_values,others,data}=response.data;
            const existingEvents = dataLayer.filter((item) => item.event === "view_item");
            if (step === 3 && existingEvents.length == 0) {
              let total = 0;

              const ecommerceData = {
                currency: "USD",
                value: data.reduce((acc, item) => parseFloat(acc) + parseFloat(item["price"]), 0),
                items: data.map((item) => ({
                  id: item["id"],
                  name: item["name"],
                  price: item["price"],
                })),
              };

              dataLayer.push({
                event: 'view_item',
                ecommerce: ecommerceData,
              });
            }

            this.setState({ optionValues: option_values, others: others, showColors: false,error:false,step: step+1,optionTypeID: optionTypeID,optionValueKey: '', stepName: optionTypes[step-1].presentation });
          });
          }
        }

      }

      clearStep=()=>{
        const {optionTypes,step,optionValueKey,summary,formData,prescriptionType,prescriptionId,optionValues}=this.state;

        const optionTypeID=optionTypes[step-2].id;

        api.LensServices.clearSelected(optionTypeID)
        .then(response => {
          const {summary}=response.data;
          this.setState({summary:summary,error:false,showColors: false});
        });

      }

      formHandler=(event)=>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(target.type == 'file')
        {
        if(name==='frames')
        this.setState({ formData: { ...this.state.formData, frames: target.files} });
        else
        this.setState({ formData: { ...this.state.formData, pdImage: target.files[0]} });
        }
        else
        this.setState({ formData: { ...this.state.formData, [name]: value} });
      }



      checkout=(cart_page)=>{
        if (this.state.checkoutButtonDisable) {
            return;
        }
        this.setState({checkoutButtonDisable: true});
        const {prescriptionType,selectedPrescriptionType,prescriptionId,formData,variantId}=this.state;
        const {order}=this.props;
        const data=prescriptionBody(variantId,selectedPrescriptionType,prescriptionType,prescriptionId,formData);
        // var formPostData = new FormData();
        //  formPostData.append("line_item",data);
          api.LensServices.checkout(order.number,data)
          .then(response => {
            const lineItemId=response.data.id;
          try{

          if(prescriptionType == 2 && prescriptionId == 0)
          {  var formPostData = new FormData();
            formPostData.append("prescription[is_pd_on_prescription]",formData.PDList);
            formPostData.append("prescription[has_two_pd]",formData.twoPDNumber);
            formPostData.append("prescription[pd_left]",formData.osLeft);
            formPostData.append("prescription[pd_right]",formData.osRight);
            formPostData.append("prescription[pd_combined]",formData.osCombined);
            formPostData.append("prescription[image]",formData.pdImage);
            api.LensServices.savePrescriptionImg(lineItemId,formPostData)
            .then(response=>{
              //console.log('prescription uploaded');
            });
          }

          if(formData.frames.length>0){
            var formPostData = new FormData();

            for (let value of Object.values(formData.frames)) {

              formPostData.append(`user_frames[images][]`,value);

            }


            api.LensServices.saveFrames(lineItemId,formPostData)
            .then(response=>{
              //console.log('frames uploaded');
            });
          }
        }
          catch(ex){
            console.log(ex);
          }


          }).then(resp=>{
            api.LensServices.orderNext(order.number)
            .then(response => {
                api.LensServices.getOrder(order.number).then(response => {
                  const current_order = response.data;
                  dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                  const ecommerceData = {
                    currency: "USD",
                    value: current_order.total,
                    items: current_order.line_items.map((item) => ({
                      id: item.id,
                      name: item.name,
                      price: item.price,
                      quantity: item.quantity,
                    })),
                  };
                  dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce: ecommerceData,
                  });
                });
              //location.href="/checkout";
             if(cart_page)
             window.location.href='/checkout';
             else
             window.location.reload();
            //  window.location.reload();
            }).catch((error) => {
              if(cart_page)
              window.location.href='/checkout';
              else
              window.location.reload();
            })
          });

      }

      selectedPrescription=(pres_id,pres_type)=>{
        this.setState({selectedPrescriptionType:pres_type,prescriptionType:pres_type,prescriptionId:pres_id});
        //this.closeModal();
      }
      selectedColor=(id,cOne,price,type,image,colorName)=>{
       this.setState({  formData: { ...this.state.formData, colorId: id,  texture: type,sunglassImage: image,colorName: colorName,colorHex: cOne, } ,  colorPrice: parseFloat(price) },()=>{
        this.sendSunglassToSession(id,type,colorName);
      });
       //this.sendSunglassToSession(id,type,colorName);
      }

      // lenseColorTextureUpdate = (type) =>{
      //   this.setState({  formData: { ...this.state.formData,  texture: type}})
      // }

      resetButton=()=>{
        api.LensServices.reset().then(response=>{
          this.setState({summary: response.data.summary,step:1});
          window.scrollTo(0, 0);
        })
      }
      backButton=()=>{
        const {optionTypes,step}=this.state;
        if(step>2)
        {
          const optionTypeID=optionTypes[step-3].id;

          api.LensServices.getOptionValues({tid: optionTypeID})
          .then(response => {
            const {option_values,others}=response.data;
            this.setState({ optionValues: option_values, others: others, showColors: false,error: false,step: step-1,optionTypeID: optionTypeID,optionValueKey: '', stepName: optionTypes[step-3].presentation });
          });
        }
        else{
          this.setState({step: step-1, stepName: "Lens Type"})
        }


      }
      changeTexture=(name,intensityBar)=>{
        const formData={...this.state.formData};
        formData.texture=name;
        formData.showIntensityBar=intensityBar;
        // formData.color_type_id=id;

        this.setState({formData: formData});
      }
      updateTwoPDNumber=(value)=>{
        this.setState({ formData: { ...this.state.formData,twoPDNumber: value  } });
      }

      selectedName=(typeId)=>{
        const {data}=this.state.summary;
        if(typeId == 0)
        {
             const selectedObj =data.find(x=>x.product == true);
             return selectedObj? selectedObj.name : "";
        }
        else
        {
          const selectedObj =data.find(x=>x.type_id == typeId);
          return selectedObj? selectedObj.name : "";
        }

      }

      // colorName=(name)=>{
      //    const {colorId,texture,colorName}=this.state.formData;
      //   return colorId != ''? `${name} ${texture}(${colorName})`:name;
      // }

      otherBifocalfunc=()=>{
        this.setState({ displayOtherBifocal:!this.state.displayOtherBifocal});
      }
      selectedOptionLens=(id,is_product)=>{
        const {data}=this.state.summary;
        const selectedItem=is_product? data.find(x=>x.id == id && x.product) : data.find(x=>x.id == id && !x.product);
        return selectedItem? true : false;
      }
      intensitySelected=(value)=>{
        this.setState({ formData: {...this.state.formData,colorIntensity:value}});

      }
      solidIntensitySelected=(value)=>{
        this.setState({ formData: {...this.state.formData,solidcolorIntensity:value}});

      }

      togglePrescriptionAdditionalOptions = () => {
        this.setState({ showPrescriptionAdditionalOptions: !this.state.showPrescriptionAdditionalOptions });
      }


  render () {
    const {user,isModalActive,isTabImage,isPresc,isdctr,optionTypes,prescriptionError,optionValues,step,stepName,optionValueKey,others,formData,showColors,summary,warrantyOptions,colorPrice}=this.state;
    const {products,userPrescriptions,lens_color}=this.props;

    let show_clear_btn = false;
    if(stepName.toLowerCase().includes('coating')) {
      show_clear_btn = true;
    }

    const showCommentBox=(is_button)=>{
      const typeIndex= (step == 1)? step-1 : step-2;
      if(is_button)
      {
        const previousButton=(step>=2)?<a href={"#step-"+(step-1)} onClick={this.backButton} className="back-button"><img src="/arrow.jpg" height="20" width="20"/><p> Back</p></a> : '';

        if(optionTypes[typeIndex].name == "comment")
      {
        return  (<div><div className="col-12 col-sm-6 col-md-12 order-continue-button desktop-button-checkout">
        {previousButton}
        <div className="Checkout-button">
          <a className="btn btn-primary rounded btn-checkout" onClick={()=>this.checkout(true)} disabled={this.state.checkoutButtonDisable}>{this.state.checkoutButtonDisable ? 'Processing...' : 'Proceed to Checkout'}</a>
        </div>
        <div className="another-order-button">
        {/* <a className="reset-button" onClick={this.resetButton}>Reset</a> */}
        <a className="btn btn-primary rounded btn-another-pair" onClick={()=>this.checkout(false)}>Order another pair</a>
        </div>
        </div>


        <div className="col-12 col-sm-6 col-md-12 order-continue-button mobile-button-checkout">
        {previousButton}


        <div className="another-order-button">
        <div className="Checkout-button">
          <a className="btn btn-primary rounded btn-checkout" onClick={()=>this.checkout(true)}>Proceed to Checkout</a>
        </div>
        <a className="btn btn-primary rounded btn-another-pair" onClick={()=>this.checkout(false)}>Order another pair</a>
        </div>
        </div>
        </div>
        );
      }
      else
      return (<div className={`col-12 col-sm-6 col-md-12 order-continue-button ${step == 1?'first-continue-btn':''}`}>
                {previousButton}

					      <div className="continue-button">
						    <a onClick={this.nextStep} className="btn btn-primary rounded btn-continue">Continue</a>
                { this.state.error && <p className="error">Select one of the above options</p> }
					      </div>
                {show_clear_btn &&
                <div className="continue-button">
						    <a onClick={this.clearStep} className="btn btn-primary rounded btn-continue">Clear</a>
                </div>
                }
            </div>);
            }
      else
     {
      return renderSectionBody(typeIndex);
     }
    }

    const renderSectionBody = (typeIndex) => {
      switch(optionTypes[typeIndex].name) {
        case "comment":
          return <Comment formData={formData} warrantyOptions={warrantyOptions} warrantyChanges={this.warrantyChanges} formHandler={this.formHandler} />;
        case "frame_type":
          return <React.Fragment>
            <LensSelection summary={summary} showDualElement={this.showDualElement} user={user} intensitySelected={this.intensitySelected}  solidIntensitySelected={this.solidIntensitySelected} showColors={showColors} selectedColor={this.selectedColor}  changeTexture={this.changeTexture} lensColor={lens_color}  nestedSelection={this.nestedSelection} stepName={stepName} nestedSelected={this.state.nestedSelected} displayOtherBifocal={this.state.displayOtherBifocal} otherBifocalfunc={this.otherBifocalfunc} isFrame={false} prescriptionError={prescriptionError} selectedOptionLens={this.selectedOptionLens} closeModal={this.closeModal} isModalActive={isModalActive} isTabImage={isTabImage} isPresc={isPresc} isdctr={isdctr} selectSavedPrescription={this.selectSavedPrescription} prescriptionId={this.state.prescriptionId} selectedPrescriptionType={this.state.selectedPrescriptionType} updateTwoPDNumber={this.updateTwoPDNumber} others={others} dateHandler={this.dateHandler} selected={optionValueKey+1} formData={formData} userPrescriptions={userPrescriptions} selectPrescription={(id, type) => this.selectedPrescription(id, type)} formHandler={this.formHandler} callback={this.categorySelection} optionValueKey={optionValueKey} step={step} showForm={others} products={products} optionValues={optionValues} />
            <i><p className="frame-type-notice">Wrap-Around, Oversized, and Specialty frames may incur an extra fee. <a href="/contact-us" target="_blank" style={{color: 'blue'}}>Contact us</a> before ordering if you would like to check.</p></i>
          </React.Fragment>
        default:
          return <LensSelection summary={summary} showDualElement={this.showDualElement} user={user} intensitySelected={this.intensitySelected}  solidIntensitySelected={this.solidIntensitySelected} showColors={showColors} selectedColor={this.selectedColor}  changeTexture={this.changeTexture} lensColor={lens_color}  nestedSelection={this.nestedSelection} stepName={stepName} nestedSelected={this.state.nestedSelected} displayOtherBifocal={this.state.displayOtherBifocal} otherBifocalfunc={this.otherBifocalfunc} isFrame={false} prescriptionError={prescriptionError} selectedOptionLens={this.selectedOptionLens} closeModal={this.closeModal} isModalActive={isModalActive} isTabImage={isTabImage} isPresc={isPresc} isdctr={isdctr} selectSavedPrescription={this.selectSavedPrescription} updateTwoPDNumber={this.updateTwoPDNumber} others={others} dateHandler={this.dateHandler} selected={optionValueKey+1} formData={formData} userPrescriptions={userPrescriptions} selectPrescription={this.selectedPrescription} formHandler={this.formHandler} callback={this.categorySelection} optionValueKey={optionValueKey} step={step} showForm={others} products={products} optionValues={optionValues} />;
      }
    }


    return (
      <React.Fragment>
          <section className="listing">
            <div className="container order-processing-bar">
              <LensStaging callback={this.selectedStep}   position={step} type={optionTypes}/>
            </div>

            <div className="container">
		            <div className="row">
                  <div className="col-12 col-md-12 detail-section">
                    <div className="col-12 col-sm-12 col-lg-8 lens-type">
                      <div id="step-1" className="col-12 col-sm-6 col-md-12 lenses-types">
                        <div className="panel-group d-accordion">
                          <div className="panel panel-default">
                            <div className={step>1? "":"panel-heading"} data-toggle="collapse" data-parent=".d-accordion"  aria-expanded={1 == step?"true":"false"}>
                              <div className="panel-title" onClick={()=>this.selectedStep(1)}>
                                <div className={"oval-checkout"+(1 == step?"-active":(1 > step?"-inactive":"-active past-selected"))}>
                                    <div className={"oval-checkout-number"+(1 == step?"":(1 > step?"":" past-selected"))}>1</div>
                                </div>
                                <h3 className={"order-processing-lsiting"+(1 <=step?"":"-toggle")}> Lens Type </h3>
                                <div className="button-myaccount-view-all past-selected" style={{display: step>1? '':'none'}}>
                                                   <a className="btn btn-primary rounded view-all-myaccount past-selected change-pencil"  href="#">
                                                    <i className="fas fa-pencil-alt"></i>
                                                   </a>
                                                   <a className="btn btn-primary rounded view-all-myaccount past-selected change-text"  href="#">
                                                   Edit</a>
                                               </div>
                                               <div className="prescription-lenses-subheading past-selected">
                                                  {this.selectedName(0)}
                                               </div>
                              </div>
                            </div>
                            <div  className={"panel-collapse collapse"+(1 == step?"in":"")} aria-expanded={1 == step?"true":"false"} >
                              <div className="panel-body">
                                  <div className="panel-body">
                                    {products.map((x,key)=>(
                                          <div className="col-6 col-sm-6 col-md-6 lenses" key={key}>
                                            <div className={"lens-product "+(key == parseInt(optionValueKey) || this.selectedOptionLens(x.id,true)?'active':'')}  onClick={()=>this.categorySelection(x.id,key)} key={key}>
                                              <img src={x.image} className="img-prescription-lenses" alt={x.name}/>
                                              <div key={key+'s'} className="prescription-lenses-heading">{x.name}</div>
                                              <div key={key} className="prescription-lenses-subheading" dangerouslySetInnerHTML={{__html: x.description}} /></div>
                                            </div>

                                    ))}
                                    {optionTypes.length>0 && showCommentBox(true)}
                                  </div>
                                </div>
                            </div>
                         </div>
                        </div>
                      </div>


                      {optionTypes.map((x,key)=>(
                        <div id={"step-"+(key+2)} className="col-12 col-sm-6 col-md-12 lenses-types" key={key}>
                          <div className="panel-group d-accordion">
                            <div className="panel panel-default">
                              <div className={key+2<step? '':"panel-heading"} data-toggle="collapse" data-parent=".d-accordion"   aria-expanded={key+2 == step?"true":"false"}>
                                <div className="panel-title" onClick={()=>this.selectedStep(key+2)}>
                                    <div className={"oval-checkout"+(key+2 == step?"-active":(key+2 < step?"-active past-selected":"-inactive"))}>
                                        <div className={"oval-checkout-number"+(key+2 == step?"":(key+2 < step?" past-selected":" next-selected"))}>{key+2}</div>
                                    </div>
                                    <h3 className={"order-processing-lsiting"+(key+2 <= step?"":"-toggle")}>
                                    {x.presentation }
                                    {x.presentation.toLowerCase().includes('coating') && <span className="coating-sub-head">  (Scratch + UV Coating)</span>  }

                                    </h3>
                                    <div className="button-myaccount-view-all past-selected"  style={{display: key+2<step? '':'none'}}>

                                        <a className="btn btn-primary rounded view-all-myaccount past-selected change-pencil"  href="#">
                                                    <i className="fas fa-pencil-alt"></i>
                                        </a>
                                        <a className="btn btn-primary rounded view-all-myaccount past-selected change-text"  href="#">
                                                   Edit
                                        </a>
                                    </div>
                                     <div className={"prescription-lenses-subheading past-selected"+(key+2 == step?"":(key+2 < step?"":" margen-top-unset"))}>
                                     {this.selectedName(x.id)}
                                     </div>
                                </div>
                              </div>
                              <div id={key+2} className={"panel-collapse collapse "+(key+2 == step?"in":"")} aria-expanded={key+2 == step?"true":"false"} >
                                <div className="panel-body">
                                  <div className="panel-body">
                                    {optionTypes.length>0 && showCommentBox(false)}                                     
                                    
                                    {optionTypes.length>0 && showCommentBox(true)}
                                   
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      ))}
                       <div className="col-12 col-sm-6 col-md-12 order-continue-button desktop-button-checkout"> 
                                    <div className="another-order-button">
                                      <a className="reset-button" onClick={this.resetButton}>CLEAR OPTIONS</a>
                                    </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-4">
                          <Summary details={summary} resetButton={this.resetButton} extra={formData.texture} colorPrice={colorPrice} callback={this.checkout}/>
                    </div>
                  
                </div>
               

              
                
                </div>
            </div>
                   
                  
                
            </section>
            
      </React.Fragment>
    );
  } 
}

ChooseLens.propTypes = {
    products: PropTypes.array

};
export default ChooseLens
