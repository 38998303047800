import React from "react";
import api from "./EndPoints";
import Shipment from "../container/Shipment";
import Payment from "../container/Payment";
import { errorMessage } from "../extensions/validate";
import FormValidator from "../extensions/FormValidator";
import LineItems from "../container/LineItems";
import CheckoutSummary from "../container/CheckoutSummary";
import {
  currencySymbol,
  errorHandling,
  payloadAddressObj,
  addressPayloadBuild
} from "../extensions/extensions";
import Confirm from "../container/Confirm";
import CheckoutProgress from "../container/CheckoutProgress";
import Loader from "react-loader-spinner";
import AddressForm from "../container/Address";
import MeasurementForm from "../container/MeasurementForm";

class Checkout extends React.Component {
  constructor() {
    super();
    this.billvalidator = new FormValidator(errorMessage);
    this.shipvalidator = new FormValidator(errorMessage);
    this.state = {
      isSameAddress: true,
      currentState: "address",
      formDirty: false,
      savedPayments: [],
      isLoading: true,
      promoCode: "",
      errorMsg: "",
      promoMsg:'',
      promo: {
        code: "",
        message: "promo applied",
        id: "",
        applied: false,
        amount: 0
      },
      billingAddress: {
        // email:'',
        addressId: 0,
        countryId: "",
        stateId: "",
        firstName: "",
        lastName: "",
        address: "",
        addressOne: "",
        city: "",
        zipcode: "",
        phone: ""
      },
      changesInBillingForm:false,
      shippingAddress: {
        // email:'',
        addressId: 0,
        countryId: "",
        stateId: "",
        firstName: "",
        lastName: "",
        address: "",
        addressOne: "",
        city: "",
        zipcode: "",
        phone: ""
      },
      changesInShippingForm:false,
      shippingForm: {
        id: "",
        methodId: "",
        shipId: "",
        itemLabelGenOptionsId: ""
      },
      isSamePd:true,
      measurementForm: {},
      measurementImage: {},
      shipTotal: 0,
      itemTotal: 0,
      orderTotal: 0,
      paymentForm: {
        id: 0,
        type: "gateway",
        num: "",
        code: "",
        name: "",
        expiry: "",
        isExisting: false
      },
      order: {},
      loading: false,
      shippingMethods: [],
      addressList: [],
      addressOptions: [],
      countryList: [],
      billStateList: [],
      shipStateList: [],
      selectedAddressId: 0,
      billvalidation: this.billvalidator.valid(),
      shipvalidation: this.shipvalidator.valid(),
      isTextOptIn: false,
      selectedPaymentOption: 'card',
    };
  }
  componentWillMount() {
    const { order_number, addressList, saved_payments } = this.props;

    let addressOptions = [];
    //order api for fetching order details
    api.LensServices.getOrder(order_number).then(resp => {
      //console.log(resp.data);
      this.stateHandling(resp);
    });

    addressList.forEach(x => {
      addressOptions.push({
        id: x.id,
        name: Object.keys(x)
          .map(function(k) {
            return k == "id" ? "" : x[k];
          })
          .filter(Boolean)
          .join(', ')
      });
    });
    //user saved addresses
    this.setState({
      addressOptions: addressOptions,
      addressList: addressList,
      savedPayments: saved_payments || []
    }); //, shippingMethods: shippingMethods,currentState:currentState});
  }

  componentDidMount() {
    api.LensServices.country().then(response => {
      const { countries } = response.data;
      this.setState({ countryList: countries });
      //,optionValues: option_values
    });
  }

  updateAddressList = (addresslist) => {


  }

  changeBillStateList = e => {
    this.billFormHandler(e);
    api.LensServices.state(e.target.value).then(response => {
      const { states } = response.data;
      this.setState({ billStateList: states });
    });
  };

  changeShipStateList = e => {
    this.shipFormHandler(e);
    api.LensServices.state(e.target.value).then(response => {
      const { states } = response.data;
      this.setState({ shipStateList: states });
    });
  };

  partiallyRecalculateOrder = resp => {
    const orderObj = resp.data;

    this.setState({
        order: orderObj,
        itemTotal: orderObj.item_total,
        orderTotal: parseFloat(orderObj.item_total) + parseFloat(orderObj.adjustment_total) + parseFloat(this.state.shipTotal),
        isLoading: false,
      }
    )

    const promoObj = orderObj.adjustments.find(
      x => x.source_type === "Spree::PromotionAction"
    );
    if (promoObj) {
      this.setState({
        promo: {
          ...this.state.promo,
          id: promoObj.adjustable_id,
          amount: parseFloat(promoObj.amount)
        },
        promoCode: promoObj.label.match(/\(([^)]+)\)/)[1]
      });
    }
  }

  stateHandling = resp => {
    const orderObj = resp.data;
    const selectedShip =
      orderObj.shipments.length > 0
        ? orderObj.shipments[0].selected_shipping_rate.id
        : 0;
    const shipmentId =
      orderObj.shipments.length > 0 ? orderObj.shipments[0].id : 0;


    let addressOptions = [];

    if( orderObj.user_addresses.length > 0){
      orderObj.user_addresses.forEach(x => {
        addressOptions.push({
          id: x.id,
          name: [`${x.firstname} ${x.lastname}`, x.address1, x.address2, x.city ,x.phone]
            .filter(Boolean)
            .join(', ')
        });
      });

      this.setState({
        addressOptions: addressOptions,
        addressList: orderObj.user_addresses
      });

    }




    this.setState({
      order: orderObj,
      isTextOptIn: orderObj.isTextOptIn,
      shipTotal: orderObj.ship_total,
      errorMsg: "",
      orderTotal: orderObj.total,
      isLoading: false,
      itemTotal: orderObj.item_total,
      shippingForm: {
        ...this.state.shippingForm,
        id: selectedShip,
        shipId: shipmentId,
        itemLabelGenOptionsId: orderObj.frame_box_shipping_label_type
      }
    });
    if (selectedShip != 0) {
      this.setState({ shippingMethods: orderObj.shipments[0].shipping_rates });
    }
    const promoObj = orderObj.adjustments.find(
      x => x.source_type == "Spree::PromotionAction"
    );
    if (promoObj) {
      this.setState({
        promo: {
          ...this.state.promo,
          id: promoObj.adjustable_id,
          amount: parseFloat(promoObj.amount)
        },
        promoCode: promoObj.label.match(/\(([^)]+)\)/)[1]
      });
    }

    if (orderObj.state == "address" && orderObj.ship_address) {
      this.fillBAddress(orderObj.bill_address);
      this.fillSAddress(orderObj.ship_address);
      api.LensServices.state(orderObj.bill_address.country_id).then(
        response => {
          const { states } = response.data;
          this.setState({ billStateList: states });
          //,optionValues: option_values
        }
      );

      api.LensServices.state(orderObj.ship_address.country_id).then(
        response => {
          const { states } = response.data;
          this.setState({ shipStateList: states });
          //,optionValues: option_values
        }
      );
    }
    if (orderObj.state == "payment") {
      if (orderObj.payment_methods.length > 0)
        this.setState({
          paymentForm: {
            ...this.state.paymentForm,
            id: orderObj.payment_methods[0].id
          }
        });
    }

    orderObj.line_items.map(item => {
      let id = item.id;
      this.setState({
        measurementForm: {
          ...this.state.measurementForm,
          [id]: {
            pd_calculated:item.pd_calculated,
            has_two_pd: item.has_two_pd,
            pd_left: item.pd_left,
            pd_right: item.pd_right,
            pd_combined: item.pd_combined
          }
        }
      });
    });


    this.setState({isSamePd: orderObj.isSamePd})


    orderObj.line_items.map(item => {
      let id = item.id;
      this.setState({
        measurementImage: { ...this.state.measurementImage, [id]: {} }
      });
    });
  };
  promoHandler = e => {
    this.setState({ promo: { ...this.state.promo, code: e.target.value } });
  };
  removePromo = () => {
    const { promo, order, promoCode } = this.state;
    this.loadingUI();
    api.LensServices.removePromo(order.number, promoCode).then(resp => {
      this.setState({ promo: { ...promo, code: "", id: "", applied: false,amount: 0 } });
      api.LensServices.getOrder(order.number).then(response => {
        this.partiallyRecalculateOrder(response);
      });
    });
  };
  loadingUI = () => {
    this.setState({ isLoading: true });
  };
  applyPromo = () => {
    const { promo, order } = this.state;
    this.setState({promoMsg: ''});
    this.loadingUI();
    api.LensServices.applyPromo(order.number, promo.code)
      .then(resp => {
        api.LensServices.getOrder(order.number).then(response => {
          this.partiallyRecalculateOrder(response);
        });
      })
      .catch(x => {
        const { error, errors } = x.response.data;
        this.setState({
          promoMsg: errorHandling(errors, error, "promo"),
          isLoading: false
        });
      });
  };
  scrollToTop = _ =>  $(window).scrollTop(0);
  submitForm = e => {
    const {
      order,
      billingAddress,
      shippingAddress,
      shippingForm,
      paymentForm,
      isSameAddress,
      measurementForm,
      measurementImage,
      isSamePd,
      isTextOptIn,
      changesInBillingForm,
      changesInShippingForm
    } = this.state;
    this.loadingUI();
    if (order.state == "measurement") {
      const body = {
        measurement_attributes: measurementForm,
        isSamePd: isSamePd
      };


      //TODO - VALIDATIONS
      if (true) {
        let heightImageData = new FormData();
        Object.keys(measurementImage).map((mi) => {
          heightImageData.append(`Image[${mi}]`, measurementImage[mi]);
        })

        api.LensServices.saveHeightCalculationImage(heightImageData).then(
          response => {
            api.LensServices.measurement(body, order.number)
              .then(resp => {
                this.stateHandling(resp);
                this.scrollToTop();
              })
              .catch(x => {

                const { error, errors } = x.response.data;
                this.setState({
                  errorMsg: errorHandling(errors, error, order.state),
                  isLoading: false
                });
              });
          }
        );
      } else {
        this.setState({
          errorMsg: "Please select shipping method",
          isLoading: false
        });
      }
    } else if (order.state == "address") {
      const billvalidation = this.billvalidator.validate(billingAddress);
      let shipvalidation = {};
      this.setState({ billvalidation });
      const billaddressAttr = payloadAddressObj(billingAddress,order.user_id);
      let shipAddressAttr = {};
      if (!isSameAddress) {
        shipvalidation = this.shipvalidator.validate(shippingAddress);
        this.setState({ shipvalidation });
        shipAddressAttr = payloadAddressObj(shippingAddress,order.user_id);
      }

      const body = addressPayloadBuild(billaddressAttr,billingAddress.addressId,changesInBillingForm,shipAddressAttr,shippingAddress.addressId,changesInShippingForm,isSameAddress,isTextOptIn)

      // const body = {
      //   bill_address_attributes: billaddressAttr,
      //   ship_address_attributes: isSameAddress
      //     ? billaddressAttr
      //     : shipAddressAttr,
      //   use_billing: isSameAddress ? 1 : 0,
      //   isTextOptIn: isTextOptIn
      // };

      // this.setState({formDirty:true});
      if (billvalidation.isValid && (isSameAddress || shipvalidation.isValid)) {
        api.LensServices.orderUpdate(body, order.number)
          .then(resp => {
            api.LensServices.address(body, order.number)
              .then(response => {
                this.stateHandling(response);
                this.scrollToTop();
              })
              .catch(x => {
                const { error, errors } = x.response.data;
                this.setState({
                  errorMsg: errorHandling(errors, error, order.state),
                  isLoading: false
                });
              });
          })
          .catch(x => {
            const { error, errors } = x.response.data;
            this.setState({
              errorMsg: errorHandling(errors, error, order.state),
              isLoading: false
            });
          });
      } else {
        this.setState({ isLoading: false });
      }
    } else if (order.state == "delivery") {
      const body = {
        shipments_attributes: {
          "0": {
            selected_shipping_rate_id: shippingForm.id,
            id: shippingForm.shipId
          }
        },
        frame_box_shipping_label_type: shippingForm.itemLabelGenOptionsId
      };
      if (shippingForm.id != "" || shippingForm.id != 0) {
        api.LensServices.delivery(body, order.number)
          .then(resp => {
            this.stateHandling(resp);
            this.scrollToTop();
          })
          .catch(x => {
            const { error, errors } = x.response.data;
            this.setState({
              errorMsg: errorHandling(errors, error, order.state),
              isLoading: false
            });
          });
      } else {
        this.setState({
          errorMsg: "Please select shipping method",
          isLoading: false
        });
      }
    } else if (order.state == "payment") {
      let body = {};
      //const validation = this.validator.validate(address);
      if (paymentForm.isExisting) {
        body = { order: { existing_card: paymentForm.id } };
      } else {

        if(paymentForm.type == 'paypal') {
          this.setState({
            errorMsg: "Please click on Paypal icon",
            isLoading: false
          });
          return
        }
        let paymentSource = {};
        const card_expiry = paymentForm.expiry.split("/");
        paymentSource[paymentForm.id] = {
          number: paymentForm.num,
          month: card_expiry[0],
          year: "20" + card_expiry[1],
          verification_value: paymentForm.code,
          name: paymentForm.name
        };
        body = {
          order: {
            payments_attributes: [
              {
                payment_method_id: paymentForm.id
              }
            ]
          },
          payment_source: paymentSource
        };
      }
      if (paymentForm.id != "" || paymentForm.id != 0) {
        api.LensServices.payment(body, order.number)
          .then(resp => {
            this.stateHandling(resp);
            this.scrollToTop();
          })
          .catch(x => {
            const { error, errors } = x.response.data;
            this.setState({
              errorMsg: errorHandling(errors, error, order.state),
              isLoading: false
            });
          });
      } else {
        this.setState({
          errorMsg: "Please select payment method",
          isLoading: false
        });
      }
    } else {
      api.LensServices.confirm(order.number, {isTextOptIn: isTextOptIn}).then(resp => {
        sessionStorage.setItem('order_completed', 'true');
        window.location.href = `/orders/${order.number}`;
      });
    }
  };
  shipmentHandling = (id, type, is_label_gen) => {
    const { shippingMethods, itemTotal, shippingForm, promo } = this.state;
    const shipAttr = { ...shippingForm };
    if (is_label_gen) {
      shipAttr.itemLabelGenOptionsId = id;
    } else {
      const selectedShipment = shippingMethods.find(x => x.id == id);
      this.setState({
        orderTotal: +selectedShipment.cost + +itemTotal + promo.amount,
        shipTotal: selectedShipment.cost
      });

      shipAttr.id = id;
      shipAttr.methodId = type;
    }
    this.setState({ shippingForm: shipAttr });
  };

  measurementFormHandler = (id, value, name) => {
    if (name == "has_two_pd", value == true) {
      this.setState(prevState => ({
        measurementForm: {
          ...prevState.measurementForm,
          [id]: {
            ...prevState.measurementForm[id],
            has_two_pd: value,
            pd_calculated: null,
            pd_left: null,
            pd_right: null,
            pd_combined: false
          }
        }
      }));
    } else if (name == "pd_combined", value == false) {
      this.setState(prevState => ({
        measurementForm: {
          ...prevState.measurementForm,
          [id]: {
            ...prevState.measurementForm[id],
            pd_combined: value,
            pd_calculated: null,
            has_two_pd: false,
            pd_left: false,
            pd_right: false,
          }
        }
      }));
    } else
    if(name == "pd_calculated") {
      this.setState(prevState => ({
        measurementForm: {
          ...prevState.measurementForm,
          [id]: {
            pd_calculated: value,
            has_two_pd: null,
            pd_left: null,
            pd_right: null,
            pd_combined: null
          }
        }
      }));
    }else
    if(name == "isSamePd") {
      this.setState({
        isSamePd: value.target.checked
      });
    } else {
      this.setState(prevState => ({
        measurementForm: {
          ...prevState.measurementForm,
          [id]: {
            ...prevState.measurementForm[id],
            [name]: value
          }
        }
      }));
    }
  };

  measurementImageHandler = (id, e) => {
    let file = e.target.files[0];
    this.setState(prevState => ({
      measurementImage: {
        ...prevState.measurementImage,
        [id]: file
      }
    }));
  };

  billFormHandler = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      billingAddress: { ...this.state.billingAddress, [name]: value },
      formDirty: false,
      changesInBillingForm: true
    });
  };

  shipFormHandler = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      shippingAddress: { ...this.state.shippingAddress, [name]: value },
      formDirty: false,
      changesInShippingForm: true
    });
  };

  selectDropDownBAddress = event => {
    const addressId = event.target.value;
    const { addressList } = this.state;

    const selectedAddress = addressList.find(x => x.id == addressId);
    if (selectedAddress != null){
      this.fillBAddress(selectedAddress);
    }else {
      this.fillBAddress({address1: "",
                        address2: "",
                        city: "",
                        country_id: "",
                        firstname: "",
                        id: -1,
                        lastname: "",
                        phone: "",
                        state_id: "",
                        zipcode: "",
                  });
    }

    api.LensServices.state(selectedAddress.country_id).then(response => {
      const { states } = response.data;
      this.setState({ billStateList: states });
      //,optionValues: option_values
    });


      this.setState({changesInBillingForm: false})
  };
  selectDropDownSAddress = event => {
    const addressId = event.target.value;
    const { addressList } = this.state;
    const selectedAddress = addressList.find(x => x.id == addressId);
    if (selectedAddress != null){
      this.fillSAddress(selectedAddress);
    }else {
      this.fillSAddress({address1: "",
                        address2: "",
                        city: "",
                        country_id: "",
                        firstname: "",
                        id: -1,
                        lastname: "",
                        phone: "",
                        state_id: "",
                        zipcode: "",
                  });

    }

    api.LensServices.state(selectedAddress.country_id).then(response => {
      const { states } = response.data;
      this.setState({ shipStateList: states });
      //,optionValues: option_values
    });


      this.setState({changesInShippingForm: false})

  };

  fillBAddress = selectedAddress => {

    this.setState({
      billingAddress: {
        ...this.state.billingAddress,
        addressId: selectedAddress.id,
        countryId: selectedAddress.country_id,
        stateId: selectedAddress.state_id,
        firstName: selectedAddress.firstname,
        lastName: selectedAddress.lastname,
        address: selectedAddress.address1,
        addressOne: selectedAddress.address2 || "",
        city: selectedAddress.city,
        zipcode: selectedAddress.zipcode,
        phone: selectedAddress.phone
      }
    });
  };
  fillSAddress = selectedAddress => {
    this.setState({
      shippingAddress: {
        ...this.state.shippingAddress,
        addressId: selectedAddress.id,
        countryId: selectedAddress.country_id,
        stateId: selectedAddress.state_id,
        firstName: selectedAddress.firstname,
        lastName: selectedAddress.lastname,
        address: selectedAddress.address1,
        addressOne: selectedAddress.address2 || "",
        city: selectedAddress.city,
        zipcode: selectedAddress.zipcode,
        phone: selectedAddress.phone
      }
    });
  };

  paymentUpdate=(id,type)=>{
    const paymentForm={...this.state.paymentForm};
    paymentForm.id=id;
    paymentForm.type=type;
    if(type=='paypal')
    SpreePaypalExpress.paymentMethodID = id;
    this.setState({ paymentForm : paymentForm, formDirty: true});
    this.setState({
      errorMsg: "",
      isLoading: false
    });
  }

  paymentFormHandler = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      paymentForm: { ...this.state.paymentForm, [name]: value }
    });
  };
  changeState = async step => {
    const { number } = this.state.order;
    this.loadingUI();
    api.LensServices.orderUpdate({ state: step }, number).then(async resp => {
      let currentState = resp.data.state;

      //this.stateHandling(resp);
      let sequenceRan = false;
      while (step != currentState) {
        await api.LensServices.orderNext(number).then(response => {
          currentState = response.data.state;
          if (currentState == step) {
            sequenceRan = true
            this.stateHandling(response);
          }
        });
      }
        if(sequenceRan == false) {
          this.stateHandling(resp)
        }

    });
  };
  handlePayment = exists => {
    this.setState({
      paymentForm: {
        ...this.state.paymentForm,
        isExisting: exists,
        // id: "",
        type: !exists ? "gateway" : this.state.paymentForm.type
      },
      errorMsg: "",
      isLoading: false
    });
  };
  selectedExistingCard = e => {
    const id = e.target.value;
    this.setState({ paymentForm: { ...this.state.paymentForm, id: id } });
  };
  handleCheckboxChange = event => {
    this.setState({ isSameAddress: event.target.checked });
  };

  changeIsTextOptIn = e =>{
    this.setState({isTextOptIn: e.target.checked})
  }

  selectPaymentOption = (event) => {
    this.setState({selectedPaymentOption: event.target.value})
  }

  render() {
    const {
      order,
      billingAddress,
      shippingAddress,
      addressOptions,
      formDirty,
      errorMsg,
      itemTotal,
      isLoading,
      savedPayments,
      shipTotal,
      orderTotal,
      shippingForm,
      paymentForm,
      billvalidation,
      shipvalidation,
      countryList,
      shipStateList,
      billStateList,
      promo,
      measurementForm,
      promoMsg,
      isSamePd,
      isTextOptIn,
      changesInShippingForm,
      changesInBillingForm
    } = this.state;
    let billvalidate = formDirty
      ? this.billvalidator.validate(billingAddress)
      : billvalidation;
    let shipvalidate = formDirty
      ? this.shipvalidator.validate(shippingAddress)
      : shipvalidation;
    //  let validatePayment= formDirty ?  this.validator.validate(paymentForm) : validation;
    // if the form has been submitted at least once
    // then check validity every time we render

    const selectedForm = () => {
      let formContent = "";
      switch (order.state) {
        case "measurement":
          formContent = (
            <MeasurementForm
              lineItems={order.line_items}
              measurementForm={measurementForm}
              formHandler={this.measurementFormHandler}
              imageHandler={this.measurementImageHandler}
              isSamePd={isSamePd}
            ></MeasurementForm>
          );
          break;
        case "address":
          formContent = (
            <React.Fragment>

              <AddressForm
                addressName="Billing Address"
                address={billingAddress}
                initial={formDirty}
                fillAddress={this.selectDropDownBAddress}
                validation={billvalidate}
                countryList={countryList}
                stateList={billStateList}
                changeStateList={this.changeBillStateList}
                addressList={addressOptions}
                formHandler={this.billFormHandler}
              />
              {this.state.isSameAddress &&    <label className="same-as-billing">
                <input type="checkbox" name="isTextOptIn" className="checkbock-same-as-shipping" checked={isTextOptIn} onChange={this.changeIsTextOptIn} />
                GET UPDATES VIA TEXT
              </label>}
              <label className="same-as-billing">
                <input
                  type="checkbox" className="checkbock-same-as-shipping"
                  defaultChecked={this.state.isSameAddress}
                  onChange={this.handleCheckboxChange}
                />
                SHIPPING SAME AS BILLING
              </label>
              {!this.state.isSameAddress && (
                <>
                <AddressForm
                  addressName="Shipping Address"
                  address={shippingAddress}
                  initial={formDirty}
                  fillAddress={this.selectDropDownSAddress}
                  validation={shipvalidate}
                  countryList={countryList}
                  stateList={shipStateList}
                  changeStateList={this.changeShipStateList}
                  addressList={addressOptions}
                  formHandler={this.shipFormHandler}
                />
                  <label className="same-as-billing">
                    <input type="checkbox" name="isTextOptIn" className="checkbock-same-as-shipping" checked={isTextOptIn} onChange={this.changeIsTextOptIn} />
                    GET UPDATES VIA TEXT
                </label>
                </>
              )}
            </React.Fragment>
          );
          break;
        case "delivery":
          formContent = (
            <Shipment  shippingMethods={order.shipments? order.shipments: []} shippingForm={shippingForm} updateAttributes={this.shipmentHandling} ship_address={order.ship_address} />
          );
          break;
        case "payment":
          formContent = (
            <Payment savedPayments={savedPayments} formHandler={this.paymentFormHandler} paymentUpdate={this.paymentUpdate} selectedExistingCard={this.selectedExistingCard} payment={paymentForm} changePaymentOption={this.handlePayment} amount={order.total}  paymentMethods={order.payment_methods} selectedPaymentOption={this.state.selectedPaymentOption} selectPaymentOption={(event) => { this.selectPaymentOption(event) }} />

          );
          break;
        case "confirm":
          formContent = (
            <Confirm
              billAddress={order.bill_address}
              shipAddress={order.ship_address}
              shipments={order.shipments}
              payments={order.payments}
            />
          );
          break;
      }
      return formContent;
    };

    const getSubmitButtonName = () => {
      const buttonTexts = {
        measurement: "Continue to address",
        address: "Continue to shipping",
        delivery: "Continue to payment",
        payment: "Continue to confirm",
        confirm: "Place order",
      };

      const buttonText = buttonTexts[order.state] || "Save & Continue";

      if (["confirm", "payment"].includes(order.state)) {
        const eventNames = {
          payment: "add_shipping_info",
          confirm: "add_payment_info",
        };
        const eventName = eventNames[order.state];

        const existingEvents = dataLayer.filter((item) => item.event === eventName);
        if (existingEvents.length === 0) {
          dataLayer.push({ ecommerce: null });
          const promoNames = order.promotions ? order.promotions.map((promo) => promo.name) : [];
          const promotionsNames = promoNames.join(', ');
          const lineItemTotals = order.line_items.map((line_item) => line_item.price * line_item.quantity);
          const total = lineItemTotals.reduce((acc, curr) => acc + curr, 0);
          const ecommerceData = {
            currency: "USD",
            value: total,
            coupon: promotionsNames,
            items: order.line_items.map((item) => ({
              id: item.id,
              name: item.name,
              price: item.price,
              discount: item.adjustment_total,
              quantity: item.quantity,
            })),
          };
          if (order.state === "payment") {
            ecommerceData.shipping_tier = order.shipments[0].selected_shipping_rate.name;
          } else if (order.state === "confirm") {
            ecommerceData.payment_type = order.payments[0].payment_method.name;
          }
          dataLayer.push({
            event: eventName,
            ecommerce: ecommerceData,
          });
        }

      }

      return buttonText;
    };

    const showSubmitButton = () => {
      switch(order.state) {
        case "payment":
          return this.state.selectedPaymentOption === 'card';
        default:
          return true;
      }
    }

    const getSubmitButtonClass = () => {
      let buttonClassName = "jsx-address-con-btn";
      switch (order.state) {
        case "address":
          buttonClassName = "save-button-checkout-single-page1";
          break;
        case "delivery":
          buttonClassName = "jsx-delivery-con-btn";
          break;
        case "payment":
          buttonClassName = "save-button-checkout-single-page2";
          break;
        case "confirm":
          buttonClassName = "jsx-confirm-con-btn";
          break;
      }

      return buttonClassName;
    };

    return (
      <div className="card single-page-checkout">
        <div className="row">
          <div className="col-sm-12 single-page-checkout-nav">
            {order.checkout_steps && (
              <CheckoutProgress order={order} changeState={this.changeState} />
            )}
          </div>
          <div className="col-sm-7 form-section">
            {selectedForm()}
            <span className="error-text-checkout">{errorMsg}</span>

            {showSubmitButton() && (<div
              className={`form-group save-button-checkout-single-page ${getSubmitButtonClass()}`}
            >
              <button
                className="btn btn-primary rounded save-continue "
                onClick={this.submitForm}
              >
                {getSubmitButtonName()}
              </button>
              <Loader
                type="Watch"
                color="#116fde"
                className="loader-clock"
                height={30}
                width={30}
                visible={isLoading}
              />
            </div>)}
            {/*order.state == 'confirm' &&  <div>
              <input type="checkbox" name="isTextOptIn" className="isTextOptIn" checked={isTextOptIn} onChange={this.changeIsTextOptIn} />
              <label>Get Updates Via Text</label>
            </div>*/}
          </div>
          <div className="col-sm-5 review-section">
            <div className="card">
            <CheckoutSummary lineItems={order.line_items||[]} promoMsg={promoMsg} itemTotal={itemTotal} currency={currencySymbol(order.currency)} removePromo={this.removePromo} shipTotal={shipTotal} adjustments={order.adjustments?order.adjustments:[]}  promo={promo} promoHandler={this.promoHandler} applyPromo={this.applyPromo} status={order.state}  orderTotal={orderTotal}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkout;

