import React from "react";
import OnlinePresFields from "../container/OnlinePresFields";

//import 'react-select2-wrapper/css/select2.css';


export const EnterOnline=(props)=> {


return (
  <React.Fragment>
   <h2  className="how-to-i-read" >How do I read my glasses prescription (Rx)? <span className="pres_read_modal" style={{cursor:'pointer'}} data-toggle="modal" data-target="#pres_read_modal">Learn More</span> </h2>
    <div className="form-group">
    <label className="prescription-label">Prescription's Name:</label>
    <input type="text" className="form-control form-custom" name="prescriptionName" onChange={props.formHandler} value={props.formData.prescriptionName} placeholder="Name this prescription..."/>
    {props.prescriptionError?<div className="valid-check cart_remove_item"> Missing Prescription Name</div>:''}
    </div>
    <div className="table-responsive">
      <OnlinePresFields updateTwoPDNumber={props.updateTwoPDNumber} formHandler={props.formHandler} formData={props.formData} />
    </div>
  </React.Fragment>
);






}
export default EnterOnline;