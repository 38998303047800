import React, { Component} from 'react';
import LineItems from './LineItems';
import TextField from './TextField';


const CheckoutSummary=(props)=> {
 
     const addZero = (num) => {
       
      
        var value = Number(num);
       value = value.toFixed(2);
        
        return value
     }
     const showPromo=()=>{
      const promoObj=props.adjustments.find((x)=> x.source_type=="Spree::PromotionAction");
      return promoObj? '': promoText();
}
const promoText=()=>{

      const applyPromoKey = (e) => {
        const keyCode = (e.keyCode ? e.keyCode : e.which)

        if (keyCode == 13 || e.key === 'Enter'){
          props.applyPromo();
        }
   
      }

 return <div className="col-sm-12 apply-promo-wrap">
      <TextField formHandler={props.promoHandler} keyPress={applyPromoKey} name="promoCode" type="text" inValid={false} errorMsg={''} value={props.promo ? props.promo.code : ''} placeholder="Enter Promo Code" label=""/>
      <div className="col-sm-6 apply-promo-wrap-button">
      <button  onClick={props.applyPromo} className="btn btn-primary promo-btn checkout-page-promo">Add Promo</button>
      </div>
    </div>
}

   
    return (<div id="checkout-summary" data-hook="checkout_summary_box" className="col-md-12">
    <h3>Order Summary</h3>
    <LineItems items={props.lineItems? props.lineItems: []} />
<table className="table item-total" data-hook="order_summary">
<tbody>
<tr>
  <td className="cart-total-item-heading"><strong>Item Total:</strong></td>
  <td className="cart-total-item"><strong>{props.currency} {addZero(props.itemTotal.toString())}</strong></td>
</tr>
</tbody>
<tbody>
<tr data-hook="shipping_total">
        <td className="cart-total-item-heading">Shipping total:</td>
        <td className="cart-total-item">{props.currency} {addZero(props.shipTotal.toString())}</td>
      </tr>
  
  {props.adjustments.length > 0 && props.adjustments.map(x=>(<tr>
        <td className="cart-total-item-heading">{x.label}: {props.status!='confirm' && <span onClick={props.removePromo} className="cart_remove_item">remove</span>}</td>
  <td  className="cart-total-item">{x.display_amount} </td>
  </tr>))}



<tr data-hook="order_total">
  <td className="cart-total-item-heading"><strong>Order Total:</strong></td>
  <td className="cart-total-item">
    <strong>
      <span>{props.currency} {addZero(props.orderTotal.toString())}</span>
    </strong>
  </td>
</tr>

</tbody>
</table>
{}
{(props.adjustments && props.status!='confirm') && (props.adjustments.length>0? showPromo(): promoText())}
<span className="error-text-checkout">{props.promoMsg}</span>
  </div>
      
    )
  }
  
  export default CheckoutSummary;