import React from "react"
import PropTypes from "prop-types"
import { Progress } from 'react-sweet-progress';
import {hexToRGBOpacity} from '../extensions/extensions';
class LensColor extends React.Component {

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip({
      container: 'body',
      html: true
    });
  }
  
  render () {
    const {lensColor,selectedColor,changeTexture,intensitySelected,solidIntensitySelected,summary}  =this.props;
    const {colorId, texture,colorHex,colorIntensity,solidcolorIntensity,showIntensityBar}=this.props.formData
    

    const colorVariant=(color,price,type)=>{
        return color.map((x,i)=> (  
         
            <div key={'color-variant-'+i} className={"col-sm-6 color-item color-lens-typecolor" + (colorId==x.id?' sunglasses-color-selected':'') + (texture === 'Polarized' ? ' color-lens-polarized' : '')} onClick={(e)=>selectedColor(x.id,x.color_one,price,type,x.image,x.name)} style={{backgroundColor:x.color_one}}>
             <a style={{color: "white"}} className="color-type-name">   
                   {x.name}      
            </a>
          
            </div>
        ));
    }
    const colorTypeCardTooltipText=(cardtext)=>{
      if (cardtext == "Polarized & Mirror Finish")
        return "A permanently colored lens used for sunglasses with a mirror finish coating on the outside. Polarized lenses with a mirror finish highly reduce the amount of reflecting light that enters the eye and make images appear sharper and crisper.<br /><br />Polarized lenses with a mirror finish are great for driving as well as outdoor activities and give your sunglasses a completely unique look."
      else if (cardtext == "Polarized")
       return "A permanently colored lens used for sunglasses. Polarized lenses highly reduce the amount of reflecting light that enters the eye and make images appear sharper and crisper.<br /><br /> Polarized lenses are great for driving as well as outdoor activities. "
      else if (cardtext == "Gradient Tint")
       return "A permanently colored lens used for fashion or for sunglasses. A gradient tint means the intensity of the tint decreases from the top to the bottom of the lens."
      else
        return "A permanently colored lens used for fashion or for sunglasses. A solid tint means the intensity of the tint will be the same from the top to the bottom of the lens."
  }

    const colorTypeCardText=()=>{
      return conditionallyFilteredTypes().map((x,i)=> x.colors.length>0?(
          <li className={"list-group-item lense-color-type "+ (x.type == texture ? 'active' : 'inactive')}  key={i} onClick={()=> changeTexture(x.type,x.intensity)}> 
          <p className="card-text text-color-type">
              {x.type}
              <a class="sunglasses-tooltip" href="#" data-toggle="tooltip" data-placement="top" title={colorTypeCardTooltipText(x.type)}>
						    <i class="fas fa-question-circle"></i>
              </a>
          </p>
          
          </li>):''
      );
  }


    const colorTypeCard=()=>{
         
        return lensColor.filter(l => l.type == texture).map((x,i)=> (
            <li className="list-group-item color-lens-type-color" key={i}>
                 {colorVariant(x.colors,x.price,x.type)}
            </li>)
        );
    }

    const conditionallyFilteredTypes = () => {
        
      if (summary != null && summary.data != null && summary.data.filter(ov => ov.type=="Prescription Type").length > 0) {
        
           let prescription_type_name = summary.data.filter(ov => ov.type=="Prescription Type")[0].name

           let filtered_lens_color = lensColor;
           if (prescription_type_name.toLowerCase().includes("flat top 35") || prescription_type_name.toLowerCase().includes("executive bifocal") 
               || prescription_type_name.toLowerCase().includes("trifocal 8 x 35") ){
                filtered_lens_color = lensColor.filter(l => l.type.toLowerCase() != 'polarized')
            } 

            if (prescription_type_name.toLowerCase().includes("bifocal") 
                || prescription_type_name.toLowerCase().includes("flat top") 
                || prescription_type_name.toLowerCase().includes("trifocal"))
                {
                  filtered_lens_color = filtered_lens_color.filter(l => (!l.type.toLowerCase().includes('polarized & mirror')))
                  filtered_lens_color = filtered_lens_color.map(l => {
                    if(l.type.toLowerCase() === 'polarized') {
                      let filtered_colors = l.colors;
                      filtered_colors = filtered_colors.filter(c => (!c.name.toLowerCase().includes('gradient')));
                      l.colors = filtered_colors;
                    }
                    return l;
                  })
                }
                return filtered_lens_color;
               
      } else {
        return lensColor;
      }
     
    }
  

    return (
  <div className="col-12 col-sm-6 col-md-12 lens-color-sunglass">
      <div className="card">
        <div className="card-body">
          <ul className="list-group color-type-lenses">
          {colorTypeCardText()}
          </ul>
        </div>
      </div>
  <div className="col-sm-6">
    <div className="card">
      <div className="card-body">
      {/* {sunglassImage!=='' && sunglassImage ? <img src={sunglassImage}  />: <img className="card-img" src="/glass-21.jpg" alt="Sunglass image"/>} */}
      { texture != 'Solid Tint'?
      <img className="card-img" src="/sunglass/sunglass_frame.png" style={{'background': hexToRGBOpacity(colorHex,texture,showIntensityBar?colorIntensity : 1, texture === 'Polarized' && !this.props.formData.colorName.includes('Gradient'))}} alt="Sunglass image"/>:
      <img className="card-img" src="/sunglass/sunglass_frame.png" style={{'background': hexToRGBOpacity(colorHex,texture,showIntensityBar?solidcolorIntensity : 1)}} alt="Sunglass image"/>
      }
      </div>
    </div>
  </div>
  <div className="col-sm-6">
    <div className="card">
      <div className="card-body">
        
        <ul className="list-group">
        {colorTypeCard()}
        </ul>
        {texture != 'Solid Tint' && showIntensityBar && <div className="lens-intensity-bar"> 
        <Progress percent={colorIntensity*100} status="success" />
            <div className="row"> 
              <span className="btn btn-sm btn-primary lens-intensity20" onClick={()=>intensitySelected(.20)}></span>
              <span className="btn btn-sm btn-primary lens-intensity50" onClick={()=>intensitySelected(.50)}></span>
              <span className="btn btn-sm btn-primary lens-intensity80" onClick={()=>intensitySelected(.80)}></span>
              <div className="lens-intensity-label">
              <span className="lens-intensity-label20">20%</span>
              <span className="lens-intensity-label50">50%</span>
              <span className="lens-intensity-label80">80%</span>
              </div>
            </div>
          </div>}
          
          {texture == 'Solid Tint' && showIntensityBar && <div className="lens-intensity-bar"> 
        <Progress percent={solidcolorIntensity*100} status="success" />
            <div className="row"> 
              <span className="btn btn-sm btn-primary lens-intensity20" onClick={()=>solidIntensitySelected(.20)}></span>
              <span className="btn btn-sm btn-primary lens-intensity50" onClick={()=>solidIntensitySelected(.50)}></span>
              <span className="btn btn-sm btn-primary lens-intensity80" onClick={()=>solidIntensitySelected(.80)}></span>
              <div className="lens-intensity-label">
              <span className="lens-intensity-label20">20%</span>
              <span className="lens-intensity-label50">50%</span>
              <span className="lens-intensity-label80">80%</span>
              </div>
            </div>
          </div>}
      </div>
    </div>    
  </div>
  <div className= "col-md-12 sunglass-bottom-text"><i>Color may not be exactly as it appears</i></div>
  
  </div>
    );

  }


}

export default LensColor;