import React from "react";
import PropTypes from "prop-types";
import UploadPhoto from '../components/UploadPhoto';
import ProvideLater from '../components/ProvideLater';
import EnterOnline from '../components/EnterOnline';
import ContactDoctor from "../components/ContactDoctor";
import SavedPrescription from "../components/SavedPrescription";
import CopyMyLenses from "../components/CopyMyLenses";
class FormContainer extends React.Component {
  render () {
    //const {selected}=this.state;
    const {selected,formData,formHandler,userPrescriptions,prescriptionError,selectPrescription,prescriptionId,prescriptionType,selectedPrescriptionType,dateHandler,updateTwoPDNumber,user} =this.props;
  
    const formElements=()=>{
        let formContent='';
        
        switch(selected){
            //case 1: formContent=<ProvideLater prescriptionError={prescriptionError} formData={formData} formHandler={formHandler}/>;
            //break;
            case 2: formContent=<UploadPhoto  formData={formData} prescriptionError={prescriptionError} formHandler={formHandler} updateTwoPDNumber={updateTwoPDNumber}/>; 
            break;
            case 3: formContent=<EnterOnline formData={formData} prescriptionError={prescriptionError} formHandler={formHandler} updateTwoPDNumber={updateTwoPDNumber}/>;
            break;
            case 4: formContent=<ContactDoctor formData={formData} prescriptionError={prescriptionError} dateHandler={dateHandler} formHandler={formHandler}/>
            break;
            //default: formContent=<ProvideLater formData={formData} formHandler={formHandler} />; 
        }
       
        return formContent;
    }

    return(
      
      <div className="panel-body">
        <div className="panel-body">
          <div className="col-12 col-sm-6 col-md-12 lenses-types">
               {formElements()}
          </div>
        </div>
      </div>
           

    );


  }


}

export default FormContainer;