import React from "react";
import PropTypes from "prop-types";
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
// import 'react-datepicker/dist/react-datepicker.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
//import "react-datepicker/dist/react-datepicker.css";
// import '../../assets/stylesheets/react-package';
export const ContactDoctor=(props)=> {


return (
    <div className="card">
    <div className="card-body">
      {/* <h5 className="card-title">Contact Doctor</h5>
    <p>PD Measurement is not required for you to submit your order, we will measure it with you later.</p> */}
    <div className="form-group">
    <input type="text" className="form-control" name="dName" onChange={(e)=>props.formHandler(e)} value={props.formData.dName} placeholder="Doctor's Name"/>
  </div>
  <div className="form-group">
   
    <InputMask name="dPhoneNumber" className="form-control"
         onChange={(e)=>props.formHandler(e)} value={props.formData.dPhoneNumber} mask="+1\ (999) 999-9999" maskChar=" " placeholder="Phone Number" />
  </div>

  <div className="form-group">
    <input type="text" className="form-control" name="dPatientName" onChange={(e)=>props.formHandler(e)} value={props.formData.dPatientName} placeholder="Patient Name"/>
  </div>
  <div className="form-group">
    <DatePicker name="dPatientDOB" className="form-control" onChange={props.dateHandler} selected={props.formData.dPatientDOB} placeholderText="Patient's DOB"/>
  </div>
  <div className="form-group" style={{color:"red"}}>
    {props.prescriptionError? "Fields are Required":""}
    </div>
  </div>
</div>
);






}
export default ContactDoctor;