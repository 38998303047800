import React from "react"
import PropTypes from "prop-types"
import {isEmpty} from "../extensions/extensions";
class FrameSummary extends React.Component {
  render () {
      const {resetButton,details,extra,colorPrice}=this.props;
      
      const lensProperties=()=>{
    
      return details.data.map((data,key) => <React.Fragment key={key}>
        {data.type.toUpperCase()!='FRAME' && <div className="lenses-review-cart-heading"><div className="col-6 col-sm-4 col-md-6 lenses-review-heading-left" key={key+'left'}>{data.type}:</div> <div className="col-6 col-sm-4 col-md-6 lenses-review-heading-right" key={key+'right'}> {data.name}</div>
        </div>}
       </React.Fragment>)
      }
     
    
        
    

    return (      
      <React.Fragment>
      <div className="review-section">
      <div className="lens-review-heading">Review Lens Details</div>
      <div className="lens-review-subheading"></div>
      {!isEmpty(details) && lensProperties()}
      <div className="total-line"></div>
      <div className="col-6 col-sm-4 col-md-6 lenses-review-heading-total">Total</div>
      <div className="col-6 col-sm-4 col-md-6 lenses-review-heading-price">$ {(+details.price) + (+colorPrice)}</div>
      <p>Free standard shipping and free returns on all orders</p>
      <a onClick={resetButton}>Clear Options</a>
      </div>
      <div className="review-section-last">	
					<div className="lens-review-heading-last">
          Included with every pair at zero additional cost:
					</div>
					<div className="review-details-last-image">
					<img src="/uv.svg" alt="" title="" className="img-review-details-last"/>
					<img src="/as.svg" alt="" title="" className="img-review-details-last"/>
					<img src="/scratch-resistant.svg" alt="" title="" className="img-review-details-last"/>
					</div>
					<div className="lens-review-subheading-last">
          Case and Microfiber Cleaning Cloth Included With All Orders. 
					</div>
					</div>
          </React.Fragment>


      
    );
  }
}

FrameSummary.propTypes = {
  details: PropTypes.object
};
export default FrameSummary
