import React from "react";
import api from "./EndPoints";

class SegmentPop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: "",
      showInstructions: true,
      showSuccess: false,
      error: null,
      useSamePd: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showInstructions !== this.state.showInstructions) {
      (function() {
        // The width and height of the captured photo. We will set the
        // width to the value defined here, but the height will be
        // calculated based on the aspect ratio of the input stream.
        var width = 600; // We will scale the photo width to this
        var height = 0; // This will be computed based on the input stream

        // |streaming| indicates whether or not we're currently streaming
        // video from the camera. Obviously, we start at false.

        var streaming = false;

        // The various HTML elements we need to configure or control. These
        // will be set by the startup() function.

        var video = null;
        var canvas = null;
        var startbutton = null;

        function startup() {
          if (window.pd_pop_wecam_streams != null) {
            window.pd_pop_wecam_streams.getTracks().forEach(track => track.stop());
          }
          window.pd_pop_wecam_streams = null;
          window.pd_viewport_height = 0;

          video = document.getElementById("video");
          canvas = document.getElementById("canvas");
          startbutton = document.getElementById("startbutton");

          navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then(function(stream) {
              window.pd_pop_wecam_streams = stream;
              video.srcObject = stream;
              video.play();
            })
            .catch(function(err) {
              $('#no_camera_error_pop').css('display','block');
            });

          video.addEventListener(
            "canplay",
            function(ev) {
              if (!streaming) {
                window.pd_viewport_height =
                  video.videoHeight / (video.videoWidth / width);

                // Firefox currently has a bug where the height can't be read from
                // the video, so we will make assumptions if this happens.

                if (isNaN(window.pd_viewport_height)) {
                  window.pd_viewport_height = width / (4 / 3);
                }

                video.setAttribute("width", width);
                video.setAttribute("height", window.pd_viewport_height);
                canvas.setAttribute("width", width);
                canvas.setAttribute("height", window.pd_viewport_height);
                streaming = true;
              }
            },
            false
          );
        }

        // Capture a photo by fetching the current contents of the video
        // and drawing it into a canvas, then converting that to a PNG
        // format data URL. By drawing it on an offscreen canvas and then
        // drawing that to the screen, we can change its size and/or apply
        // other changes before drawing it.

        function takepicture() {
          var context = canvas.getContext("2d");
          if (width && height) {
            canvas.width = width;
            canvas.height = height;
            context.drawImage(video, 0, 0, width, height);
            //context.beginPath();
            //context.lineWidth = "2";
            //context.strokeStyle = "white";
            //context.rect(235, 293, 130, 82);
            //context.stroke();

            $("#btn_use_this").val(canvas.toDataURL());

            $("#display_section_popup").css("display", "block");
            $("#camera_section_popup").css("display", "none");
          }
        }
        var customElement = $("<div>", {
          css: {
            border: "2px dashed white",
            "text-align": "center",
            "padding-top": "80px",
            "padding-right": "130px",
            "margin-top": "220px"
          },
          class: "your-custom-class"
        });

        $("#video").LoadingOverlay("show", {
          background: "rgba(165, 190, 100, 0)",
          image: "",
          custom: customElement
        });

        if (document.getElementById("video")) {
          startup();
        }
      })();
    }
  }

  componentWillUnmount() {
    if (window.pd_pop_wecam_streams != null) {
      window.pd_pop_wecam_streams.getTracks().forEach(track => track.stop());
    }
  }

  formHandler = (e) => {
this.setState({useSamePd: e.target.checked})
  }

  saveSegmentHImage = (lineItemId,orderNumber) => {
    let body = {
      data: this.state.data,
      line_item_id: lineItemId,
      useSamePd: this.state.useSamePd
    };

    api.LensServices.saveSegmentHImage(body)
      .then(resp => {
        if (resp.data.error == false) {
          this.setState({
            showSuccess: true
          });
          //navigate to current page
          window.location.href=`/orders/${orderNumber}/properties?p_id=4`;
        } else {
          this.setState({
            data: "",
            showInstructions: true,
            error: resp.data.message
          });
        }
      })
      .catch(x => {
        this.setState({
          data: "",
          showInstructions: true,
          error: "We were unable to save the image. Please try submitting a new image"
        });
      });
  };

  reset = () => {
    this.setState({
      showInstructions: true
    });
  };

  render() {
    const { lineItemId, closePopup ,orderNumber} = this.props;

    const transformStyle = {
      WebkitTransform: "scaleX(-1)",
      transform: "scaleX(-1)"
    };

    const togglePDInstructions = () => {
      this.setState({
        showInstructions: false,
        error: null
      });
    };

    const takePicture = () => {
      let canvas = document.getElementById("canvas");
      let context = canvas.getContext("2d");
      let video = document.getElementById("video");
      if (window.pd_viewport_height) {
        canvas.width = 600;
        canvas.height = window.pd_viewport_height;
        context.drawImage(video, 0, 0, 600, window.pd_viewport_height);
        //context.beginPath();
        //context.lineWidth = "2";
        //context.strokeStyle = "white";
        //context.rect(235, 293, 130, 82);
        //context.stroke();

        this.setState({ data: canvas.toDataURL() });

        $("#display_section_popup").css("display", "block");
        $("#camera_section_popup").css("display", "none");
      }
    };

    const pdInstruction = () => {
      return (
        <section className="edit-prescription new-prescp">
          <div className="container new-pres">
            <div className="row">
              <div className="col-12 col-md-12 detail-section instruction-pd">
                <div className="col-12 col-sm-12 col-lg-12 lens-type">
                  <div className="add-pres measure">Segment height Photo Capture Instruction</div>
                </div>
                <div className="col-12 col-sm-12 col-lg-12 pd-instruction">
                  <div className="col-4 col-sm-4 col-lg-4 pd-instruction-details">
                    <div className="pd-instruction-image">
                      <img
                        src="/pd1.jpg"
                        alt="Pd Instruction"
                        className="pd-image-instruction"
                      />
                    </div>
                    <div className="pd-instruction-text">
                      Wearing Glasses?
                      <br />
                      Take them off before getting started
                    </div>
                    <div className="pd-instruction-text-small">
                      Try to be in a well lit area with white background
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-lg-4 pd-instruction-details">
                    <div className="pd-instruction-image">
                      <img
                        src="/pd2.jpg"
                        alt="Pd Instruction"
                        className="pd-image-instruction"
                      />
                    </div>
                    <div className="pd-instruction-text">
                      Place credit card with magnetic strip under your nose, and
                      touching your face (we use this for scale)
                    </div>

                    <div className="pd-instruction-text-small">
                      Make sure its a credit card
                      <br />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-lg-4 pd-instruction-details">
                    <div className="pd-instruction-image">
                      <img
                        src="/pd3.jpg"
                        alt="Pd Instruction"
                        className="pd-image-instruction"
                      />
                    </div>
                    <div className="pd-instruction-text">
                      Look straight ahead and take a photo
                    </div>
                    <div className="pd-instruction-text-small">
                      Make sure the camera angle is straight
                    </div>
                  </div>
                </div>
                <a
                  className="btn btn-primary rounded track-myaccount pd-continue"
                  href="#"
                  onClick={() => {
                    togglePDInstructions();
                  }}
                >
                  Continue
                </a>
                <div>{this.state.error && <p className="pd-not-calculated-error-message">{this.state.error}</p>}</div>
                
              </div>
            </div>
          </div>
        </section>
      );
    };

    const pdSuccess = () => {
      return (
        <section className="edit-prescription new-prescp">
          <div className="container new-pres">
            <div className="row">
              <div className="col-12 col-md-12 detail-section instruction-pd">
                <div className="col-12 col-sm-12 col-lg-12 lens-type">
                  <div className="add-pres measure">Segment height Photo</div>
                </div>
                <div className="col-12 col-sm-12 col-lg-12 pd-instruction">
                 
                    Image saved successfully

                </div>
                
              </div>
            </div>
          </div>
        </section>
      );
    };

    return (
      <>
        (
        <div className="popup1 pd-measure-popup">
          <div className="popup_inner">
            <button
              type="button"
              className="close btn-pd-measurement"
              onClick={closePopup}
            >
              ×
            </button>
            {this.state.showInstructions == true ? (
              pdInstruction()
            ) : (
              ( this.state.showSuccess == true ) ? 
              (pdSuccess())
              :
              <div className="contentarea">
               
                <div className="camera" id="camera_section_popup">
                <div id="no_camera_error_pop">
                  <p>Please, Allow the browser to access the camera.</p>
                </div>
                  <video id="video" style={transformStyle}>
                    Video stream not available.
                  </video>
                  <button
                    id="startbutton"
                    onClick={() => {
                      takePicture();
                    }}
                  >
                    Take photo
                  </button>
                </div>
                <div id="display_section_popup" style={{ display: "none" }}>
                  <canvas id="canvas" style={transformStyle}></canvas>
                  <div className="pd-popup-reset-btn">
                    <button
                      id="resetbutton"
                      onClick={() => {
                        this.reset();
                      }}
                    >
                      Reset
                    </button>
                  </div >
                  <div className="pd-popup-calculate-btn">
                    <button
                      id="calulatebutton"
                      onClick={() => {
                        this.saveSegmentHImage(lineItemId,orderNumber);
                      }}
                    >
                      Save
                    </button>
                  </div>
                  <div>
                  
                  
                  </div>
                </div>
              </div>
               
            )}
          </div>
        </div>
        )
      </>
    );
  }
}

export default SegmentPop;
