import React from "react";



const Confirm=({billAddress,shipAddress,lineItems,shipments,payments})=>{
    


return(
    <div className="row steps-data">
    <div className="col-md-6 col-sm-6 col-xs-12 confirm-sections" data-hook="order-bill-address">
      <h4><b>Billing Address</b> </h4>
        <div className="address vcard">
            <div className="fn">{billAddress.full_name}</div>
            <div className="adr">
                <div className="street-address">
                <div className="street-address-line">
                {billAddress.address1}
                </div>
                    <div className="street-address-line">
                    {billAddress.address2}
                    </div>
                </div>
                <div className="local">
                <span className="locality">{`${billAddress.city}, `}</span>
                <span className="region">{`${billAddress.state.name}, `}</span>
                <span className="postal-code">{`${billAddress.zipcode}, `}</span>
                <div className="country-name">{billAddress.country.name}</div>
                </div>
            </div>
                <div className="tel">
                <span className="type">Phone:</span>
                {` ${billAddress.phone}`}
                </div>
        </div>

    </div>

      <div className="col-md-6 col-sm-6 col-xs-12 confirm-sections" data-hook="order-ship-address">
        <h4><b>Shipping Address</b> </h4>
        <div className="address vcard">
            <div className="fn">{shipAddress.full_name}</div>
            <div className="adr">
                <div className="street-address">
                <div className="street-address-line">
                {shipAddress.address1}
                </div>
                    <div className="street-address-line">
                    {shipAddress.address2}
                    </div>
                </div>
                <div className="local">
                <span className="locality">{`${shipAddress.city}, `}</span>
                <span className="region">{`${shipAddress.state.name}, `}</span>
                <span className="postal-code">{`${shipAddress.zipcode}, `}</span>
                <div className="country-name">{shipAddress.country.name}</div>
                </div>
            </div>
            <div className="tel">
            <span className="type">Phone:</span>
                {` ${shipAddress.phone}`}
            </div>
        </div>
      </div>

      <div className="col-md-6 col-sm-6 col-xs-12 confirm-sections" data-hook="order-shipment">
        <h4><b>Shipments</b> </h4>
        <div className="delivery">
            <div>
             {shipments.map((x,key)=>  <div key={key} > {x.selected_shipping_rate.name}</div>)}
            </div>
        </div>
        
      </div>

    <div className="col-md-6 col-sm-6 col-xs-12 confirm-sections" data-hook="order-payment">
      <h4><b>Payment Information</b> </h4>
      {payments.filter(payment => payment.state != 'invalid').map((x,key)=>  <div key={key} className="payment-info">

        {x.payment_method.name === 'Paypal' && <span className="cc-type">
          PayPal
        </span>}
        {x.payment_method.name === 'Credit Card' && <span className="cc-type">
            Ending in {x.source.last_digits}
        </span>}
        <br/>
        <span className="full-name">{x.source.name}</span>
      
      <span className="payment-amount">({x.display_amount})</span>
      
      <br/>
      
      </div>)   }
     
    </div>
</div>
)


}
export default Confirm;