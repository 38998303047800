import React from "react";
import { MultiPurposeList } from "../extensions/extensions";

const FeatureLens = props => {
  const showHide = value => {
    return value ? "" : "none";
  };
  const chooseType = presentation => {
    return presentation.indexOf("Blue") > -1 ? "PB" : "P";
  };

  const showFunc = presentation => {
    return !(presentation.indexOf("Blue") > -1
      ? props.formData.showPhotoChromicBlue
      : props.formData.showPhotoChromic);
  };

  const showDualElementPropogation = () => {
    if (
      document
        .getElementById("bifocal-wrapper-elem")
        .classList.contains("active")
    )
      return false;
    else return true;
  };

  const PhotochromicOptions = (x, key, lensLabel, brown_image) => {
    return (
      <div
        className={
          "col-12 col-sm-6 col-md-12 lenses-types heading-feature-section-toggle " +
          ((key == parseInt(props.optionValueKey) &&
            (x.presentation.indexOf("Blue") > -1
              ? props.formData.showPhotoChromicBlue
              : props.formData.showPhotoChromic)) ||
          props.selectedOptionLens(x.id)
            ? "active"
            : "")
        }
        onClick={() =>
          showFunc(x.presentation) &&
          props.showDualElement(x.id, key, chooseType(x.presentation))
        }
        key={key + "pchrome"}
      >
        <div
          className="panel-heading without-toggle choose-your-lenses-ov-pointer"
          data-toggle="collapse"
          data-parent={"#" + props.step}
          aria-expanded={
            key == parseInt(props.optionValueKey) ? "true" : "false"
          }
        >
          {props.nestedSelected.photochromic ? (
            props.nestedSelected.photochromic.includes("Gray") ? (
              <img src={x.image} className="img-prescription-lenses-type" alt="photochromatic-gray" />
            ) : (
              <img src={brown_image} className="img-prescription-lenses-type" alt="photochromatic-brown" />
            )
          ) : (
            <img src={x.image} className="img-prescription-lenses-type" alt="photochromatic" />
          )}

          <div key={key + "s"} className="lenses-type-heading">
            {x.name.includes('Blue Light') ? 'Blue Light + Light-Adjusting (Photochromic)' : 'Light-Adjusting (Photochromic)'}
          </div>
          <div
            className="row bifocal-row"
            style={{
              display:
                x.presentation.indexOf("Blue") > -1
                  ? showHide(props.formData.showPhotoChromicBlue)
                  : showHide(props.formData.showPhotoChromic)
            }}
          >
            <div
              className="col-3 col-sm-4 col-md-3"
              onClick={() =>
                props.nestedSelection(
                  `${lensLabel} Gray`,
                  chooseType(x.presentation)
                )
              }
            >
              <div
                className={`color-lenses-bifocal lens-color-gray ${
                  props.nestedSelected.photochromic ==
                  `${chooseType(x.presentation) + lensLabel} Gray`
                    ? "bifocal-active"
                    : ""
                }`}
              >
                <span className="most-popular">Most Popular</span>
                <div className="lens-color-text">Gray</div>
              </div>
            </div>
            <div
              className="col-3 col-sm-4 col-md-3"
              onClick={() =>
                props.nestedSelection(
                  `${lensLabel} Brown`,
                  chooseType(x.presentation)
                )
              }
            >
              <div
                className={`color-lenses-bifocal lens-color-brown ${
                  props.nestedSelected.photochromic ==
                  `${chooseType(x.presentation) + lensLabel} Brown`
                    ? "bifocal-active"
                    : "bifocal-inactive"
                }`}
              >
                <div className="lens-color-text">Brown</div>
              </div>
            </div>
          </div>
          <div
            key={key}
            className="lenses-type-subheading"
            dangerouslySetInnerHTML={{ __html: x.option_description }}
          />
        </div>
      </div>
    );
  };

  const showMore = (x, key) => {
    return (
      <div
        className={
          "col-12 col-sm-6 col-md-12 lenses-types bifocal-wrap-element " +
          (key == parseInt(props.optionValueKey) ||
          props.selectedOptionLens(x.id)
            ? "active"
            : "")
        }
        key={key + "showMore"}
        onClick={() => props.callback(x.id, key, false, "S")}
      >
        <div
          className="panel-heading without-toggle choose-your-lenses-ov-pointer"
          data-toggle="collapse"
          data-parent={"#" + props.step}
          aria-expanded={
            key == parseInt(props.optionValueKey) ? "true" : "false"
          }
        >
          <img src={x.image} className="img-prescription-lenses-type " alt={x.presentation} />
          <div key={key + "s"} className="lenses-type-heading">
            {x.presentation}
          </div>
          <div
            key={key}
            className="lenses-type-subheading"
            dangerouslySetInnerHTML={{ __html: x.option_description }}
          />
        </div>
      </div>
    );
  };

  const bifocalOptions = (x, key) => {
    return (
      <div
        id="bifocal-wrapper-elem"
        className={
          "col-12 col-sm-6 col-md-12 lenses-types " +
          (key == parseInt(props.optionValueKey) ||
          props.selectedOptionLens(x.id)
            ? "active"
            : "")
        }
      >
        <div
          onClick={() => {
            showDualElementPropogation() &&
              props.showDualElement(x.id, key, "B");
          }}
          key={key + "bifocal"}
        >
          <div
            className="panel-heading without-toggle choose-your-lenses-ov-pointer"
            data-toggle="collapse"
            data-parent={"#" + props.step}
            aria-expanded={
              key == parseInt(props.optionValueKey) ? "true" : "false"
            }
          >
            <img
              src={x.image}
              className={
                x.name.trim() == "Bifocal Flat Top"
                  ? "img-prescription-type"
                  : "img-prescription-lenses-type "
              }
              alt={x.presentation}
            />
            <div key={key + "b"} className="lenses-type-heading">
              Bifocal
            </div>
            <div
              key={key}
              className="lenses-type-subheading"
              dangerouslySetInnerHTML={{ __html: x.option_description }}
            />
            <div
              className="row bifocal-row lens-bi-focal"
              style={{ display: showHide(props.formData.showBifocal) }}
            >
              <div
                className="col-3 col-sm-4 col-md-3 bi-focal-colm"
                onClick={() => props.nestedSelection("Bifocal Flat Top", "B")}
              >
                <div
                  className={`color-lenses-bifocal bi-focal-cont ${
                    props.nestedSelected.bifocal == "Bifocal Flat Top"
                      ? "bifocal-active"
                      : ""
                  }`}
                >
                  <div className="lens-color-text bifocal-label">Flat Top</div>
                </div>
              </div>
              <div
                className="col-3 col-sm-4 col-md-3  bi-focal-colm"
                onClick={() => props.nestedSelection("Bifocal Round Top", "B")}
              >
                <div
                  className={`color-lenses-bifocal bi-focal-cont ${
                    props.nestedSelected.bifocal == "Bifocal Round Top"
                      ? "bifocal-active"
                      : "bifocal-inactive"
                  }`}
                >
                  <div className="lens-color-text bifocal-label">Round Top</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-12 col-sm-6 col-md-12 lenses-type"} key={key}
        style={{ display: showHide(props.formData.showBifocal) }}
        >
          <div
            className={
              !props.displayOtherBifocal
                ? "show-more-pop-len-close"
                : "show-more-pop-len-open"
            }
            onClick={props.otherBifocalfunc}
          >
            {!props.displayOtherBifocal
              ? "Show More Options"
              : "Show Less Options"}
          </div>
        </div>
      </div>
    );
  };
  const showContainer = () => {
    const key = props.otherTypekey;

    if (
      props.lensItem.name.includes(MultiPurposeList[0]) &&
      props.lensItem.name.includes("Gray")
    ) {
      let brown_photochromic = null;
      brown_photochromic = props.optionValues
        .filter(x => x.name.includes("Brown"))
        .filter(x => x.name.includes(MultiPurposeList[0]));
      let brown_photochromic_image = null;
      if (brown_photochromic.length > 0) {
        brown_photochromic_image = brown_photochromic[0].image;
      }

      return PhotochromicOptions(
        props.lensItem,
        key,
        MultiPurposeList[0],
        brown_photochromic_image
      );
    } else if (
      props.lensItem.name.includes(MultiPurposeList[1]) &&
      props.lensItem.name.includes("Bifocal")
    ) {
      if (
        "Bifocal Flat Top" == props.lensItem.name ||
        "Bifocal Round Top" == props.lensItem.name
      ) {
        return "Bifocal Flat Top" == props.lensItem.name
          ? bifocalOptions(props.lensItem, key)
          : "";
      } else return props.displayOtherBifocal && showMore(props.lensItem, key);
    } else if (
      props.lensItem.name.includes(MultiPurposeList[2]) &&
      props.lensItem.name.includes("Gray")
    ) {
      let blue_l_brown_photochromic = null;
      blue_l_brown_photochromic = props.optionValues
        .filter(x => x.name.includes("Brown"))
        .filter(x => x.name.includes(MultiPurposeList[2]));
      let blue_l_brown_photochromic_image = null;
      if (blue_l_brown_photochromic.length > 0) {
        blue_l_brown_photochromic_image = blue_l_brown_photochromic[0].image;
      }

      return PhotochromicOptions(
        props.lensItem,
        key,
        MultiPurposeList[2],
        blue_l_brown_photochromic_image
      );
    }
  };

  return <React.Fragment>{showContainer()}</React.Fragment>;
};
export default FeatureLens;
