import React from "react";
import PropTypes from "prop-types";
class ProductImage extends React.Component {
  render () {
    const {imageUrl}=this.props;

    return (
      <img src={imageUrl} />
    );
  }
}

export default ProductImage;
