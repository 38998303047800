

import React from "react";
import PropTypes from "prop-types";
export const SelectOption=(props)=>{
    
return(<div className={`form-group ${props.inValid && 'has-error'}`}>
<label htmlFor={props.name}>{props.label}</label>
    <select className="form-control round" value={props.selectedOption} onChange={props.callback} name={props.name}>
      {props.target == 'addressList' ? 
        <option value="-1">New Address</option>: 
        <option value="">select</option>
      }
               
              {props.listOptions.map((option,key) => (
                <option value={option.id} key={key}>
                  {option.name}
                </option>
              ))}
            </select>
            <span className="help-block">{props.errorMsg}</span>
            </div>
            )
}

export default SelectOption;