import React, { Component} from 'react';
import TextField from './TextField';



class ShipmentForm extends Component {
  
  
    render() {
      const {  shippingMethods,shippingForm,updateAttributes,ship_address } = this.props;
      

      const isCanadianShipment = () => {
        if(ship_address.country_id == 38) {
           return true;
        } else {
           return false;
        }
     }
     
      const shippingOptions=()=>{
        return shippingMethods.map((shipObj,index)=> (
          shipObj.shipping_rates.map((x,key)=>
        <li className="list-group-item" key={key+"radio"+index} onClick={()=>updateAttributes(x.id,x.shipping_method_id,false)}><input type="radio" className="payment-option-button" onChange={()=>updateAttributes(x.id,x.shipping_method_id,false)} value= {x.id} checked={shippingForm.id==x.id} name={"shipping"+key} type="radio"  label="Shipping"/>{x.name} <span className="badge rate-cost">{x.display_cost}</span> </li>)))
      }

      const itemLabelGenOptionsUsa=()=>{
        return (<>
        
        <li className={shippingForm.itemLabelGenOptionsId== '1' ? "list-active list-group-item":"list-group-item"} onClick={()=>updateAttributes(1, null, true)}><input type="radio" className="payment-option-button" onChange={()=>updateAttributes(1, null, true)} value="1" checked={shippingForm.itemLabelGenOptionsId== '1'} name={"itemLabelGen1"} type="radio"  label="Shipping"/> Receive a free pre-paid USPS shipping label by email after checking out  </li>
        <li className={shippingForm.itemLabelGenOptionsId== '2' ? "list-active list-group-item":"list-group-item"} onClick={()=>updateAttributes(2, null, true)}><input type="radio" className="payment-option-button" onChange={()=>updateAttributes(2, null, true)} value="2" checked={shippingForm.itemLabelGenOptionsId== '2'} name={"itemLabelGen2"} type="radio"  label="Shipping"/> Send me a free mailing kit with a pre-paid USPS return shipping label</li>
        <li className={shippingForm.itemLabelGenOptionsId== '0' ? "list-active list-group-item":"list-group-item"}  onClick={()=>updateAttributes(0, null, true)} ><input type="radio" className="payment-option-button" onChange={()=>updateAttributes(0, null, true)} value="0" checked={shippingForm.itemLabelGenOptionsId== '0'} name={"itemLabelGen1"} type="radio"  label="Shipping"/> Ship in my frame using my own preferred shipping method  </li>
        
        {shippingForm.itemLabelGenOptionsId== '2'  && <p className="frame-box-option-sub">Adds 2-4 business days to total order time</p>}
        </>)
      }

      const itemLabelGenOptionsCanada=()=>{
        return (<>
            <li className={shippingForm.itemLabelGenOptionsId== '0' ? "list-active list-group-item":"list-group-item"}  onClick={()=>updateAttributes(0, null, true)} ><input type="radio" className="payment-option-button" onChange={()=>updateAttributes(0, null, true)} value="0" checked={shippingForm.itemLabelGenOptionsId== '0'} name={"itemLabelGen1"} type="radio"  label="Shipping"/> Ship in my frame using my own preferred shipping method  </li>
          </>)
      }

      return (
        <>
         <div className="row">
        <div className="col-sm-12 checkout-address-single-page">
          <h1 className="heading-cart-section">How Would You Like To Send In Your Frame ?</h1>
          <ul className="list-group">
           { isCanadianShipment() ? itemLabelGenOptionsCanada() : itemLabelGenOptionsUsa()}
          </ul>
        </div>
      </div>

        <div className="row">
          <div className="col-sm-12 checkout-address-single-page">
            <h1 className="heading-cart-section">Return Shipping Method</h1>
            <ul className="list-group">
            {shippingMethods.length > 0 && shippingOptions()}
            </ul>
           
          </div>
         
         
          {/* {adjustments && adjustments.length>0? showPromo(): promoText()} */}
        </div>
      </>
      );
    }
  }
  
  export default ShipmentForm;