import React from "react";
import PropTypes from "prop-types";
export const ProvideLater=(props)=> {


return (
  <React.Fragment>
    <p>We will email you after you place your order for your Prescription.</p>
    <p>PD Measurement is not required for you to submit your order, we will measure it with you later</p>
    <div className="form-group">
      <input type="number" className="form-control form-custom" name="osLeft" onChange={props.formHandler} value={props.formData.osLeft} placeholder="OS (Left)"/>
      <input type="number" className="form-control form-custom" name="osRight" onChange={props.formHandler} value={props.formData.osRight} placeholder="OS (Right)"/>
    </div>
    <div className="form-group"  style={{color:"red"}}>
    {props.prescriptionError? "Fields are Required":""}
    </div>
    <div className="form-group">
      <em>(Pricing is $10 / eye.)</em>
    </div>
  </React.Fragment>
);






}
export default ProvideLater;