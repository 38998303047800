import React from 'react';
import PropTypes from 'prop-types';

class CopyMyLenses extends React.Component {
  render() {
    return <React.Fragment>
      <p style={{color: "#000"}}>A licensed optician will examine your lenses, and we will use the exact same prescription to fabricate your new lenses. We will also copy your Pupil Distance (PD) and any other necessary measurements.</p>
    </React.Fragment>
  }
}

export default CopyMyLenses;
