import React from "react";
import PropTypes from "prop-types";
import InputMask from 'react-input-mask';
export const TextField=(props)=> {



return (<div className={`form-group ${props.inValid && 'has-error'}`  }>
          <label htmlFor={props.name}>{props.label}</label>
          {props.name=='phone'?
              <InputMask name="phone" className="form-control round"
              name={props.name} placeholder={props.placeholder}
              onChange={props.formHandler} value={props.value} mask="+1\ (999) 999-9999" maskChar=" " />
              : (
                props.name=='expiry'?
                <InputMask name="expiry" className="form-control round" 
                name={props.name} placeholder={props.placeholder}
                onChange={props.formHandler} value={props.value} mask="99/99" maskChar=" " />
                :
                 (props.name=='num' ? 
                      <InputMask name="num" className="form-control round" 
                      name={props.name} placeholder={props.placeholder}
                      onChange={props.formHandler} value={props.value} mask="9999999999999999" maskChar=" " />
                      : (props.name=='code' ? 
                          <InputMask name="code" className="form-control round" 
                          name={props.name} placeholder={props.placeholder}
                          onChange={props.formHandler} value={props.value} mask="9999" maskChar=" " />:
                          (props.name=='promoCode' ? 
                              <input type={props.type} className="form-control round"
                              name={props.name} placeholder={props.placeholder}
                              onChange={props.formHandler} onKeyPress={(e) => props.keyPress(e)} value={props.value}/>:

                              <input type={props.type} className="form-control round"
                                name={props.name} placeholder={props.placeholder}
                                onChange={props.formHandler} value={props.value}/>
                            ) 
                        )
                  
                )
              )
              }
          <span className="help-block">{props.errorMsg}</span>
        </div>);

}

export default TextField;