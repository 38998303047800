import React from 'react';
import PropTypes from 'prop-types';

class PrescriptionPreview extends React.Component {
  render() {
    const findById = (element) => {
      return element.id === parseInt(this.props.prescriptionId);
    }

    const formatDate = (date) => {
      return (new Date(date)).toLocaleDateString();
    }

    const renderPrescriptionPreview = () => {
      switch (this.props.selectedPrescriptionType) {
        case 2:
          return renderUploadPrescriptionPreview();
        case 3:
          return renderPrescriptionPrescriptionPreview();
        case 4:
          return renderDoctorPrescriptionPreview();
        default:
          return null;
      }
    }

    const renderUploadPrescriptionPreview = () => {
      const prescription = this.props.userPrescriptions.upload.find(findById);
      if (prescription === null) {
        return null;
      }

      return <React.Fragment>
        <div className="img-popup-image-frames" id="prescription-image-preview">
          <img src={prescription.image} alt="vision" className="img-frames-popup"/>
        </div>
      </React.Fragment>
    }

    const renderPrescriptionPrescriptionPreview = () => {
      const prescription = this.props.userPrescriptions.prescription.find(findById);
      if (prescription === null) {
        return null;
      }

      return <React.Fragment>
        <div className="table-responsive" id="prescription-prescription-preview">
          <table className="table design">
            <tbody>
            <tr>
              <td>{prescription.prescription_name}</td>
              <td>Sphere (SPH)</td>
              <td>Cylinder (CYL)</td>
              <td>Axis</td>
              <td>Add</td>
            </tr>
            <tr>
              <td>OD (Right)</td>
              <td>{prescription.right_eye_sphere}</td>
              <td>{prescription.right_eye_cylinder}</td>
              <td>{prescription.right_eye_axis}</td>
              <td>{prescription.right_eye_add}</td>
            </tr>
            <tr>
              <td>OS (Left)</td>
              <td>{prescription.left_eye_sphere}</td>
              <td>{prescription.left_eye_cylinder}</td>
              <td>{prescription.left_eye_axis}</td>
              <td>{prescription.left_aye_add}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    }

    const renderDoctorPrescriptionPreview = () => {
      const prescription = this.props.userPrescriptions.doctor.find(findById);
      if (prescription === null) {
        return null;
      }

      return <React.Fragment>
        <div className="table-responsive" id="prescription-doctor-preview">
          <table className="table design">
            <tbody>
            <tr>
              <td className="table-profile1 doctor-name-saved">Doctor's Name:</td>
              <td className="table-profile1 doctor-name-saved"> {prescription.name} </td>
              <td className="table-profile1 doctor-name-saved">Patient's Name:</td>
              <td className="table-profile1 doctor-name-saved"> {prescription.patients_name} </td>
            </tr>
            <tr>
              <td className="table-profile1 doctor-name-saved">Mobile:</td>
              <td className="table-profile1 doctor-name-saved">{prescription.phone} </td>
              <td className="table-profile1 doctor-name-saved">Date of Birth:</td>
              <td className="table-profile1 doctor-name-saved">{formatDate(prescription.patients_dob)}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    }

    if (this.props.userPrescriptions === null || this.props.prescriptionId === null || this.props.selectedPrescriptionType == null) return null;

    return <React.Fragment>
      <div className="prescription-preview">
        {renderPrescriptionPreview()}
      </div>
    </React.Fragment>
  }
}

export default PrescriptionPreview;
