import React from "react";
import PropTypes from "prop-types";
export const Comment=(props)=> {

const warrantyList=()=>{

return <div><h5 className="card-title">Select Warranty</h5>
    <div style={{paddingLeft: '7%'}}>
   {props.warrantyOptions.map((x,key)=>
    <div className="form-group " key={key+'-warrantyID'}>
        <input type="radio" className="select-option-warranty" style={{'cursor':'pointer'}} onChange={()=>props.warrantyChanges(x.id)} value={x.id} checked={props.formData.warrantyID==x.id.toString()} />
        <label className="payment-option-button" style={{'cursor':'pointer'}} onClick={()=>props.warrantyChanges(x.id)}>{x.options_text}</label>
        Price: <p className="price-warranty">{x.price}</p>
    </div>
   )}
   </div>
  </div>

}
return (
   <React.Fragment>
       <div style={{marginTop: '6%',marginLeft: '-5%'}} >
      {props.warrantyOptions.length>0 && warrantyList()}
      {/* <h5 className="card-title"></h5> */}
      {/* <div className="form-group">
      <label className="title-add-frame">Add Frames</label>
      <input id="frames" type="file" onChange={props.formHandler} required multiple name="frames" className="choose-file-button-add-frames"></input>
      </div> */}
      </div>
      <div className="col-12 col-sm-6 col-md-12 comment-box-order-processing">
      <div className="form-group">
          <textarea name="comment" onChange={props.formHandler} className="form-control order-comments" placeholder="Anything special we should know about your frame or lenses?" />
      </div>
    
    </div>
    </React.Fragment>
);



}
export default Comment;