import React from "react";
import PropTypes from "prop-types";
import LensDropDown from '../container/LensDropdown';
import {singlePdRange, doublePdRange} from '../extensions/extensions';

export const UploadPhoto=(props)=> {
  
  const bothPDDropDown1=()=>{
    return ( <div>
      <div className="Rect1"><LensDropDown indexVal={1} callback={props.formHandler} listOptions={doublePdRange} selectedOption={props.formData.osRight} checked={props.formData.twoPDNumber? null : "disabled"} name="osRight"  className="sel2" placeholder="Right / OD" /></div>
      <div className="Rect1"><LensDropDown indexVal={2} callback={props.formHandler} listOptions={doublePdRange} selectedOption={props.formData.osLeft} checked={props.formData.twoPDNumber?  null : "disabled"} name="osLeft" className="sel2" placeholder="Left / OS" /></div>
  </div>)
}

return (
  <React.Fragment>
    <h2 className="order-processing-lsiting h2-custom">Prescription Image</h2>
    <br/>
    <input type="file" className="form-control-file" onChange={(e)=>props.formHandler(e)} name="pdImage"/>
    <br/>
    <div className="form-group">
      <span class="know-my-pd">I know my Pupil Distance (PD).</span> <span style={{cursor:'pointer'}} class="badge pd-info" data-toggle="modal" data-target="#pd_info_modal"> ? </span>
      <input type="checkbox" class="know-pd-checkbox" checked={props.formData.PDList} name="PDList" onChange={props.formHandler}  />
    </div>
    {props.formData.PDList == true &&
    <div className="form-group pd-box">
      <div className="table-responsive table-resp-no-overflow">
      

      <table className="table space">
        <th className="th-two-pd">
          <input type="radio" onClick={()=>props.updateTwoPDNumber(false)} checked={props.formData.twoPDNumber? false : true} />
          <label  className="label-two-pd"  onClick={()=>props.updateTwoPDNumber(false)} >Single PD</label>        
        </th>
        <th className="th-two-pd1">
          <input type="radio"  onClick={()=>props.updateTwoPDNumber(true)} checked={props.formData.twoPDNumber? true : false}/>
          <label className="label-two-pd"  onClick={()=>props.updateTwoPDNumber(true)} >Dual PD</label>        
        </th>
        <tbody>
          <tr  className="tr-two-pd">
          <td className="td-two-pd">
              <div className="pd1">
                {/* <h className="tag">PD</h>                 */}
                <div className="Rect1"><LensDropDown  indexVal={3} callback={props.formHandler} listOptions={singlePdRange} selectedOption={props.formData.osCombined}  checked={props.formData.twoPDNumber? "disabled": null} name="osCombined"  className="sel2"/> </div>
                {/* {props.formData.twoPDNumber? <p className="write" onClick={()=>props.updateTwoPDNumber(false)}>SinglePD</p>: */}
                {/* <p className="write" onClick={()=>props.updateTwoPDNumber(true)}>DualPD</p>} */}
              </div>
          </td>
          <td className="td-two-pd">
              <div className="pd1">
                {/* <h className="tag">PD</h>                 */}
                {bothPDDropDown1() }
                {/* {props.formData.twoPDNumber? <p className="write" onClick={()=>props.updateTwoPDNumber(false)}>SinglePD</p>:
                <p className="write" onClick={()=>props.updateTwoPDNumber(true)}>DualPD</p>} */}
              </div>
          </td >
          </tr>
        </tbody>
      </table>
      </div>
    </div>    
    }
    <div className="form-group"  style={{color:"red"}}>
    {props.prescriptionError? "Fields are Required":""}
    </div>
  </React.Fragment>
);


}
export default UploadPhoto;