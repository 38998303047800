import React from "react";
import PropTypes from "prop-types";
import LensSelection from "./LensSelection";
import FramesStaging from "./FramesStaging";
import ProductImage from "./ProductImage";
import FrameSummary from "./FrameSummary";
import api from "./EndPoints";
import Comment from "./Comment";
import {  prescriptionBody,prescriptionValidation } from "../extensions/extensions";

class ChooseFrames extends React.Component {

    state={
        step:1,
        stepName:'',
        optionTypeID:0,
        variantId:0,
        optionTypes:[],
        optionValues:[],
        others:false,
        linkedVariant:false,
        optionValueKey:'',
        selectedOption:1,
        showColors:false,
        warrantyOptions:[],
        summary:{ data:[],price:''},
        colorPrice: '0',
        colorSummary:{},
        prescriptionError:false,
        formData:{
          frames:[],
          texture:'solid',
          rightOptionSphere:'',
          color_id:'',
          color_type_id:'',
          rightOptionCylinder:'',
          prescriptionName:'',
          rightOptionAxis:'',
          rightOptionAdd:'',
          leftOptionSphere:'',
          leftOptionCylinder:'',
          leftOptionAxis:'',
          leftOptionAdd:'',
          twoPDNumber:false,
          warrantyID:null,
          PupillaryDistance:false,
          rightPupillaryDistance:'',
          leftPupillaryDistance:'',
          pdImage:{},
          comment:'',
          PDList:false,
          dPhoneNumber:'',
          dName:'',
          dPatientName:'',
          dPatientDOB:'',
          osLeft:'',
          osRight:'',
          osCombined:'',
          have_prism:false,
          right_prism_horizontal:'',
          right_prism_horizontal_bd:'',
          right_prism_vertical:'',
          right_prism_vertical_bd:'',
          left_prism_horizontal:'',
          left_prism_horizontal_bd:'',
          left_prism_vertical:'',
          left_prism_vertical_bd:'',
          i_know_my_pd_rx: false
        },
        prescriptionType:0,
        prescriptionId:0,
        isModalActive:false,
        isTabImage:true,
        isPresc:false,
        isdctr:false,
        user:false,
        error:false,
        productId:'',
        productImgUrl:'',

    };
    componentWillMount(){
      
      const {user,stage,option_values,option_type_id,option_types,summary,productId}=this.props.navigatedItem;
      this.setState({user: user, step: stage,optionTypes:option_types,productId: productId, optionValues: option_values,optionTypeID: option_type_id,summary:summary});
    }
    componentDidMount(){
        api.FramesServices.getOptionTypes()
            .then(response => {
            const {option_types,summary}=response.data;
            this.setState({ optionTypes: option_types, summary: summary });
            //,optionValues: option_values 
            })
            .catch(function (error) {
            console.log(error);
            });

        api.FramesServices.getOptionValues({})
            .then(response => {
                const {option_values,others}=response.data;
                this.setState({ optionValues: option_values, others: others });
            //,optionValues: option_values 
            })
            .catch(function (error) {
            console.log(error);
            });  
        api.FramesServices.getProductImage(this.state.productId)
        .then(response => {
          const {images}= response.data;
          // console.log(images[0].large_url)
          this.setState({productImgUrl: images[0].large_url});
        });   
    }

    closeModal=()=>{
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      this.setState({isModalActive:false});
      
    }

    selectSavedPrescription=(selectedtab)=>{
      this.setState({isTabImage: false,isPresc:false,isdctr:false});
      this.selectedSaved(selectedtab)
    }

    selectedSaved=(selectedtab)=>{
      this.setState({[selectedtab] : true});
    }

    selectedStep=(step)=>{
    if (this.state.step > step){
        const {optionTypes}=this.state;
        if(step==0){
        this.setState({step: step+1 });
        }
        else{

        
        if(optionTypes[step-1].name=="comment")
        {
        api.FramesServices.getVariant().then(resp=>{
            const {variant_id,warranty,summary}=resp.data;
            this.setState({variantId: variant_id,warrantyOptions: warranty,summary:summary});
        }).catch((error) => {
            console.log(error);
        });
        }

        const optionTypeID=optionTypes[step==1 ? 0 : step-1].id;
        api.FramesServices.getOptionValues({tid: optionTypeID})
        .then(response => {
            const {option_values,others}=response.data;
            this.setState({ optionValues: option_values, others: others,showColors: false, stepName: optionTypes[(optionTypes.length < step)? step-2 : step-1].presentation,step: step,optionTypeID: optionTypeID,optionValueKey: '',others: false  });
        });
        
        }
    }
    }

    dateHandler=(date)=>{
    this.setState({ formData: { ...this.state.formData, dPatientDOB:date}});
    }

    categorySelection=(productId,optionValueKey,model=false)=>{ 
        if(this.state.others){
            this.setState({ error: false,prescriptionType: optionValueKey+1,isModalActive:model});         
        }
    
        const {optionValues,optionTypes}=this.state;
        if(optionValues.length>0)
        { 
          this.setState({error: false,prescriptionError:false, isModalActive:model}) 
        
        }

        api.FramesServices.getOptionTypes({tid: this.state.optionTypeID,o_vid: productId})
        .then(response => {
          const {summary}= response.data;
          this.setState({error: false,  optionValueKey:optionValueKey,summary: summary ,isModalActive:model});
        });
    }

    nextStep=()=>{
      const {optionTypes,step,optionValueKey,summary,formData,prescriptionType,prescriptionId,optionValues}=this.state;
      let height = (step*113 + 170)
      window.scrollTo(0,height);
      if(optionValueKey === "" && summary.data.length<=step && optionValues.length>0 ){
          this.setState({error:true});
      }else{
          const optionTypeID=optionTypes[step].id;
          let errorPrescription=false;
          if(optionTypes[((step < 1)? 1 : step) - 1].name=='prescription' && prescriptionId==0){
            errorPrescription= prescriptionValidation(formData,prescriptionType, prescriptionId);
            this.setState({prescriptionError: errorPrescription});
          }
          if(!errorPrescription){   
            if(optionTypes[step].name=="comment") {
              api.FramesServices.getVariant().then(resp=>{
                  const {variant_id,warranty,summary}=resp.data;
                  this.setState({variantId: variant_id,warrantyOptions: warranty,summary:summary});
              }).catch((error) => {
                  console.log(error);
              });
            }        
            api.FramesServices.getOptionValues({tid: optionTypeID})
            .then(response => {
              const {option_values,others}=response.data;
              this.setState({ optionValues: option_values, others: others, showColors: false,error:false,step: step+1,optionTypeID: optionTypeID,optionValueKey: '', stepName: optionTypes[step-1].presentation });
            });
          }
        }
    }

    formHandler=(event)=>{
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(target.type=='file')
    { 
    if(name==='frames')
    this.setState({ formData: { ...this.state.formData, frames: target.files} });
    else
    this.setState({ formData: { ...this.state.formData, pdImage: target.files[0]} });
    }
    else
    this.setState({ formData: { ...this.state.formData, [name]: value} });
    }
    
    checkout=(cart_page)=>{
    const {prescriptionType,prescriptionId,formData,variantId}=this.state;
    const {order}=this.props;
    const data=prescriptionBody(variantId,prescriptionType,prescriptionId,formData);
    // var formPostData = new FormData();
    //  formPostData.append("line_item",data);
        api.FramesServices.checkout(order.number,data)
        .then(response => {
        const lineItemId=response.data.id;
        try{
        
        if(prescriptionType==2 && prescriptionId==0)
        {  var formPostData = new FormData();
        formPostData.append("prescription[is_pd_on_prescription]",formData.PDList);
        formPostData.append("prescription[has_two_pd]",formData.twoPDNumber);
        formPostData.append("prescription[pd_left]",formData.osLeft);
        formPostData.append("prescription[pd_right]",formData.osRight);
        formPostData.append("prescription[pd_combined]",formData.osCombined);
        formPostData.append("prescription[image]",formData.pdImage);
        api.FramesServices.savePrescriptionImg(lineItemId,formPostData)
        .then(response=>{
            console.log('prescription uploaded');
        });
        }

        if(formData.frames.length>0){
        var formPostData = new FormData();
        
        for (let value of Object.values(formData.frames)) {
            
            formPostData.append(`user_frames[images][]`,value);
            
        }
        
        
        api.FramesServices.saveFrames(lineItemId,formPostData)
        .then(response=>{
            console.log('frames uploaded');
        });
        }
    }
        catch(ex){
        console.log(ex);
        }
        
        
        }).then(resp=>{
        api.FramesServices.orderNext(order.number)
        .then(response => {
          api.LensServices.getOrder(order.number).then(response => {
            const current_order = response.data;
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            const ecommerceData = {
              currency: "USD",
              value: current_order.total,
              items: current_order.line_items.map((item) => ({
                id: item.id,
                name: item.name,
                price: item.price,
                quantity: item.quantity,
              })),
            };
            dataLayer.push({
              event: 'add_to_cart',
              ecommerce: ecommerceData,
            });
          });
            //location.href="/checkout";
            if(cart_page)
            window.location.href='/checkout';
            else
            window.location.reload();
        //  window.location.reload();
        }).catch((error) => {
            if(cart_page)
            window.location.href='/checkout';
            else
            window.location.reload();
        })
        });
        
    }

    selectedPrescription=(pres_id,pres_type)=>{
    this.setState({ prescriptionType:pres_type,prescriptionId:pres_id});
    this.closeModal();
    }

    resetButton=()=>{
      api.FramesServices.reset().then(response=>{          
        window.location.reload();
      })
    }
    backButton=()=>{
    const {optionTypes,step}=this.state;
    if(step>2)
    {
        const optionTypeID=optionTypes[step-3].id;
        this.setState({error: false,step: step-1,optionTypeID: optionTypeID,optionValueKey: '', stepName: optionTypes[step-3].presentation });
        api.FramesServices.getOptionValues({tid: optionTypeID})
        .then(response => {
        const {option_values,others}=response.data;
        this.setState({ optionValues: option_values, others: others, showColors: false });
        });
    }
            
    
    }
    
    updateTwoPDNumber=(value)=>{
    this.setState({ formData: { ...this.state.formData,twoPDNumber: value  } });
    }

    selectedName=(typeId)=>{
      if(this.state.summary) {
        const {data}=this.state.summary;
        if(typeId==0)
        {
                const selectedObj =data.find(x=>x.product==true);
                return selectedObj? selectedObj.name : "";
        }
        else
        {
            const selectedObj =data.find(x=>x.type_id==typeId);
            return selectedObj? selectedObj.name : "";
        }
      }
    

    }
    warrantyChanges=(id)=>{
      this.setState({formData:{...this.state.formData,warrantyID:id.toString()}});
    }

    selectedOptionLens=(id,is_product)=>{
    const {data}=this.state.summary;
    const selectedItem=is_product? data.find(x=>x.id==id && x.product) : data.find(x=>x.id==id && !x.product); 
    
    return selectedItem? true : false;
    }
    
      
  render () {
    const {user,isModalActive,isTabImage,isPresc,isdctr,optionTypes,prescriptionError,optionValues,step,stepName,optionValueKey,others,formData,showColors,summary,warrantyOptions,colorPrice}=this.state;
    const {userPrescriptions}=this.props; 
    
    const frameObj=summary?summary.data.find(x=>x.type.toUpperCase()=='FRAME'):{};
    const showCommentBox=(is_button)=>{
        const typeIndex=  step-1;
        if(is_button) {
            const previousButton=(step>=2)?<a href={"#step-"+(step-1)} onClick={this.backButton} className="back-button"><img src="/arrow.jpg" height="20" width="20"/> <p> Back</p></a> : '';
            if(optionTypes[typeIndex].name=="comment") {
                return  <div className="col-12 col-sm-6 col-md-12 order-continue-button">
                            {previousButton}
                            <div className="Checkout-button">
                            <a className="btn btn-primary rounded btn-checkout" onClick={()=>this.checkout(true)}>Proceed to Checkout</a>
                            </div>
                            <div className="another-order-button">
                            <a className="btn btn-primary rounded btn-another-pair" onClick={()=>this.checkout(false)}>Order another pair</a>
                            </div>
                        </div>;
            }else
                return (<div className="col-12 col-sm-6 col-md-12 order-continue-button">
                            {previousButton}
                            <div className="continue-button">
                                <a onClick={this.nextStep} className="btn btn-primary rounded btn-continue">Continue</a>
                                {this.state.error && <p className="error">Select any from above</p>}
                            </div>
                        </div>);
        }else{
            return optionTypes[typeIndex].name=="comment"? <Comment formData={formData} warrantyOptions={warrantyOptions} warrantyChanges={this.warrantyChanges} formHandler={this.formHandler} /> : <LensSelection user={user} isFrame={true} prescriptionError={prescriptionError}  stepName={stepName} selectedOptionLens={this.selectedOptionLens} closeModal={this.closeModal} isModalActive={isModalActive} isTabImage={isTabImage} isPresc={isPresc} isdctr={isdctr} selectSavedPrescription={this.selectSavedPrescription} updateTwoPDNumber={this.updateTwoPDNumber} others={others} dateHandler={this.dateHandler} selected={optionValueKey+1} formData={formData} userPrescriptions={userPrescriptions} selectPrescription={this.selectedPrescription} formHandler={this.formHandler} callback={this.categorySelection} optionValueKey={optionValueKey} step={step} showForm={others}  optionValues={optionValues} />;
        }
    }


    return (
      <React.Fragment>
          <section className="listing">
            <div className="prod-frame">
              {frameObj && <div className="lens-review-heading frame-head">{frameObj.name}</div>}
              <ProductImage imageUrl={this.state.productImgUrl}/>
            </div>

            <div className="container order-processing-bar framebar">
              <FramesStaging callback={this.selectedStep}   position={step} type={optionTypes}/>
            </div>
            
            <div className="container">
		            <div className="row">                  
                  <div className="col-12 col-md-12 detail-section">
                    <div className="col-12 col-sm-6 col-lg-8 lens-type">
                      


                      {optionTypes.map((x,key)=>(
                        <div id={"step-"+(key+1)} className="col-12 col-sm-6 col-md-12 lenses-types">
                          <div className="panel-group d-accordion">
                            <div className="panel panel-default">
                              <div className={key+1<step? '':"panel-heading"} data-toggle="collapse" data-parent=".d-accordion"   aria-expanded={key+1==step?"true":"false"}>
                                <div className="panel-title" onClick={()=>this.selectedStep(key+1)}>
                                    <div className={"oval-checkout"+(key+1 == step?"-active":(key+1 < step?"-active past-selected":"-inactive"))}>
                                        <div className={"oval-checkout-number"+(key+1 == step?"":(key+1 < step?" past-selected":" next-selected"))}>{key+1}</div>
                                    </div>
                                    <h3 className={"order-processing-lsiting"+(key+1 <= step?"":"-toggle")}> {x.presentation}</h3>               
                                    <div className="button-myaccount-view-all past-selected"  style={{display: key+1<step? '':'none'}}>
                                    <a className="btn btn-primary rounded view-all-myaccount past-selected change-pencil"  href="#">
                                                    <i className="fas fa-pencil-alt"></i>
                                    </a>
                                    <a className="btn btn-primary rounded view-all-myaccount past-selected change-text"  href="#">
                                         Edit</a>	   
                                    </div>
                                     <div className={"prescription-lenses-subheading past-selected"+(key+1 == step?"":(key+1 < step?"":" margen-top-unset"))}>
                                     {this.selectedName(x.id)} 
                                     </div>
                                </div>
                              </div>
                              <div id={key+1} className={"panel-collapse collapse "+(key+1==step?"in":"")} aria-expanded={key+1==step?"true":"false"} >
                                <div className="panel-body">
                                  <div className="panel-body">
                                    {optionTypes.length>0 && showCommentBox(false)}                                     
                                  
                                    {optionTypes.length>0 && showCommentBox(true)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-12 col-sm-6 col-md-12 order-continue-button desktop-button-checkout"> 
                                    <div className="another-order-button">
                                      <a className="reset-button" onClick={this.resetButton}>CLEAR OPTIONS</a>
                                    </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-4">
                          <FrameSummary details={summary} resetButton={this.resetButton} extra={formData.texture} colorPrice={colorPrice} callback={this.checkout}/>
                    </div>
                  
                </div>
               

              
                
                </div>
            </div>
                   
                  
                
            </section>
            
      </React.Fragment>
    );
  } 
}

ChooseFrames.propTypes = {
   

};
export default ChooseFrames
