
export const errorMessage = [
    // { 
    //   field: 'email', 
    //   method: 'isEmpty', 
    //   validWhen: false, 
    //   message: 'Email is required.' 
    // },
    // { 
    //   field: 'email',
    //   method: 'isEmail', 
    //   validWhen: true, 
    //   message: 'That is not a valid email.'
    // },
    { 
      field: 'phone', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide a phone number.'
    },
    { 
      field: 'firstName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide a first name.'
    },
    { 
      field: 'lastName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide a last name.'
    },
    { 
      field: 'address', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide a address.'
    },
    { 
      field: 'city', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide a city.'
    },

    { 
      field: 'countryId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide a country.'
    },
    { 
      field: 'stateId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide a state.'
    },
    { 
      field: 'zipcode', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide zipcode.'
    }
  ];
  



