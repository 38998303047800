import React from "react";
import PropTypes from "prop-types";
import PrescriptionPreview from "./PrescriptionPreview";
class SavedPrescription extends React.Component {
  render() {
    const formatDate = (date) => {
      return (new Date(date)).toLocaleDateString();
    }

    const capitalize = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const handlePrescriptionSelected = (event) => {
      const option = $(event.target).find('option:selected');
      this.props.selectPrescription(parseInt(option.val()), parseInt(option.data('prescription-type')));
      return true;
    }

    const userPrescriptionsEmpty = (prescriptions) => {
      if(prescriptions === null) {
        return false;
      }
      return (prescriptions.doctor.length <= 0 && prescriptions.prescription.length <= 0 && prescriptions.upload.length <= 0)
    }

    const renderSignInButton = () => {
      return <React.Fragment>
        <div className="sign-in-button-container">
          <button className="btn btn-primary rounded" data-toggle="modal" data-target="#myLoginModal">Sign In to View Saved Prescriptions</button>
        </div>

      </React.Fragment>
    }

    const renderNoPrescriptionsMessage = () => {
      return <React.Fragment>
        <p style={{color: "#000"}}>No Prescriptions Saved to Your Account</p>
      </React.Fragment>
    }

    const renderPrescriptionsOptgroup = (prescriptions) => {
      if(prescriptions.prescription.length <= 0) {
        return null
      }

      return <optgroup label="Prescriptions">
        {
          prescriptions.prescription.map((x, key) =>
            <option label={x.prescription_name + ": added on " + formatDate(x.created_at)} data-prescription-type="3" selected={this.props.prescriptionId===x.id && this.props.selectedPrescriptionType===3}>{x.id}</option>
          )
        }
      </optgroup>
    }

    const renderUploadsOptgroup = (prescriptions) => {
      if(prescriptions.upload.length <= 0) {
        return null
      }

      return <optgroup label="Images">
        {
          prescriptions.upload.map((x, key) =>
            <option label={capitalize(x.name) + ": added on " + formatDate(x.created_at)} data-prescription-type="2" selected={this.props.prescriptionId===x.id && this.props.selectedPrescriptionType===2}>{x.id}</option>
          )
        }
      </optgroup>
    }

    const renderDoctorsOptgroup = (prescriptions) => {
      if(prescriptions.doctor.length <= 0) {
        return null
      }

      return <optgroup label="Doctors">
        {
          prescriptions.doctor.map((x, key) =>
            <option label={x.name + ": added on " + formatDate(x.created_at)} data-prescription-type="4" selected={this.props.prescriptionId===x.id && this.props.selectedPrescriptionType===4}>{x.id}</option>
          )
        }
      </optgroup>
    }

    const renderPrescriptionsSelect = (prescriptions) => {
      return <React.Fragment>
        <select id="savedPrescriptionsSelect" name="savedPrescriptions" className="form-control" onChange={handlePrescriptionSelected}>
          <option label="Select saved prescription..." selected={this.props.prescriptionId === undefined || this.props.prescriptionId === 0 || this.props.selectedPrescriptionType === undefined || this.props.selectedPrescriptionType === 0} disabled hidden />
          {renderUploadsOptgroup(prescriptions)}
          {renderPrescriptionsOptgroup(prescriptions)}
          {renderDoctorsOptgroup(prescriptions)}
        </select>
        <PrescriptionPreview userPrescriptions={this.props.userPrescriptions} prescriptionId={this.props.prescriptionId} selectedPrescriptionType={this.props.selectedPrescriptionType} />
      </React.Fragment>
    }

    if(!this.props.user) { return renderSignInButton() }
    if(userPrescriptionsEmpty(this.props.userPrescriptions)) { return renderNoPrescriptionsMessage() }
    return renderPrescriptionsSelect(this.props.userPrescriptions)
  }
}
export default SavedPrescription;
